import Signal from "@webgl/lib/Signal";
export default class Sound {
    constructor(source) {
        this.onEnded = () => {
            this.onComplete.emit();
            this.dispose();
        };
        this.source = source;
        this.context = this.source.module.context;
        const ctx = this.context;
        var output = this.source.module.masterNode;
        if (source.output)
            output = source.output;
        this.gain = ctx.createGain();
        this.gain.connect(output);
        this.node = ctx.createBufferSource();
        this.node.buffer = this.source.bufferSource;
        this.node.connect(this.gain);
        this.onComplete = new Signal();
        this.node.onended = this.onEnded;
    }
    init(volume, loop) {
        this.gain.gain.value = volume;
        this.node.loop = loop;
        this.startTime = this.context.currentTime;
        this.node.start();
    }
    getCurrentTime() {
        return this.context.currentTime - this.startTime;
    }
    dispose() {
        if (this.source === null)
            return;
        this.gain.disconnect();
        this.node.disconnect();
        this.node.onended = null;
        try {
            this.node.stop();
        }
        catch (e) { }
        this.gain = null;
        this.node = null;
        this.source = null;
        this.onComplete.release();
    }
}
