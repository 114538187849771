var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      staticClass: "c-PushProduct",
      class: {
        "c-PushProduct--alt": _vm.alt,
        "is-retail": _vm.$appdatas.store.isRetail
      }
    },
    [
      _c("div", { staticClass: "c-PushProduct-inner" }, [
        _c("div", { staticClass: "c-PushProduct-visual" }, [
          _c("img", {
            staticClass: "c-PushProduct-visual-img",
            attrs: { src: _vm.image.src, alt: "" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-PushProduct-content" }, [
          _c("div", { staticClass: "c-PushProduct-content-inner" }, [
            _c("h2", {
              staticClass: "c-PushProduct-heading t-h4",
              domProps: { innerHTML: _vm._s(_vm.t("el_content.push_title")) }
            }),
            _vm._v(" "),
            !_vm.$appdatas.store.isRetail
              ? _c(
                  "a",
                  {
                    staticClass: "c-PushProduct-btn t-btn t-btn--primary",
                    attrs: {
                      href: _vm.t("common.cta_buy_link"),
                      target: "_blank"
                    },
                    on: { click: _vm.onBuy }
                  },
                  [_vm._v(_vm._s(_vm.t("common.cta_buy")))]
                )
              : _c(
                  "button",
                  {
                    staticClass: "c-PushProduct-btn t-btn t-btn--retail",
                    on: { click: _vm.onRetail }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "t-btn-label t-btn-label--top" },
                      [_vm._v(_vm._s(_vm.t("common.cta_retail")))]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "t-btn-label t-btn-label--bottom",
                      domProps: {
                        innerHTML: _vm._s(_vm.t("common.cta_retail_footnote"))
                      }
                    })
                  ]
                )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }