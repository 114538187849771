import TextureCodecBaseKtx from "@webgl/assets/TextureCodecBaseKtx";
export default class TextureCodecEtc extends TextureCodecBaseKtx {
    constructor() {
        super(...arguments);
        this.name = 'etc';
    }
    isSupported(extensions) {
        return extensions.etc != null;
    }
}
