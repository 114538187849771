import { TextureType } from "nanogl/texture-base";
import BasisTranscoderBinaryUrl from '!!file-loader!vendors/basis/basis_transcoder.wasm';
import BasisTranscoder from "vendors/basis";
import { TextureDataType } from "./TextureData";
export class TextureCodecBasis {
    constructor() {
        this.name = 'basis';
    }
    static GetTranscoder() {
        if (this._transcoder === null) {
            this._transcoder = new BasisTranscoder(BasisTranscoderBinaryUrl);
        }
        return this._transcoder;
    }
    getBasisFormat(extensions) {
        if (extensions.astc !== null)
            return BasisTranscoder.BasisOutputFormat.ASTC;
        if (extensions.dxt !== null)
            return BasisTranscoder.BasisOutputFormat.DXT;
        if (extensions.etc !== null)
            return BasisTranscoder.BasisOutputFormat.ETC;
        if (extensions.pvr !== null)
            return BasisTranscoder.BasisOutputFormat.PVRTC;
        return BasisTranscoder.BasisOutputFormat.RGB565;
    }
    async decodeLod(source, lod, extensions) {
        const requestLod = source.lods[lod];
        const basisFormat = this.getBasisFormat(extensions);
        const response = await TextureCodecBasis.GetTranscoder().transcode(requestLod.buffers[0], basisFormat);
        const image = response.images[0];
        const mips = image.levels.map(l => {
            return {
                width: l.alignedWidth,
                height: l.alignedHeight,
                data: new Uint8Array(l.data),
            };
        });
        const datas = {
            datatype: (basisFormat !== BasisTranscoder.BasisOutputFormat.RGB565) ? TextureDataType.RAW_COMPRESSED : TextureDataType.RAW,
            textureType: TextureType.TEXTURE_2D,
            width: image.width,
            height: image.height,
            internalformat: image.format.internalformat,
            format: image.format.format,
            type: image.format.type,
            sources: [{
                    surfaces: [mips]
                }]
        };
        source.datas = datas;
    }
    async decodeCube(source, extensions) {
        const requestLod = source.lods[0];
        const transcoder = TextureCodecBasis.GetTranscoder();
        const basisFormat = this.getBasisFormat(extensions);
        const response = await transcoder.transcode(requestLod.buffers[0], basisFormat);
        const image = response.images[0];
        const mips = image.levels.map(l => {
            return {
                width: l.alignedWidth,
                height: l.alignedHeight,
                data: new Uint8Array(l.data),
            };
        });
        const datas = {
            datatype: (basisFormat !== BasisTranscoder.BasisOutputFormat.RGB565) ? TextureDataType.RAW_COMPRESSED : TextureDataType.RAW,
            textureType: TextureType.TEXTURE_CUBE,
            width: image.width,
            height: image.height,
            internalformat: image.format.internalformat,
            format: image.format.format,
            type: image.format.type,
            sources: [{
                    surfaces: []
                }]
        };
        datas.sources[0].surfaces.push(mips);
        for (let i = 1; i < source.lods.length; i++) {
            const requestLod = source.lods[i];
            const basisFormat = this.getBasisFormat(extensions);
            const response = await transcoder.transcode(requestLod.buffers[0], basisFormat);
            const image = response.images[0];
            const mips = image.levels.map(l => {
                return {
                    width: l.alignedWidth,
                    height: l.alignedHeight,
                    data: new Uint8Array(l.data),
                };
            });
            datas.sources[0].surfaces.push(mips);
        }
        source.datas = datas;
    }
    isSupported(extensions) {
        return window.WebAssembly !== undefined;
    }
}
TextureCodecBasis._transcoder = null;
