import GltfTypes from '../types/GltfTypes';
import { createNativeSignal } from '../lib/cancellation';
const _HAS_CIB = (window.createImageBitmap !== undefined);
const GL_REPEAT = 0x2901;
const GL_MIRRORED_REPEAT = 0x8370;
export function filterHasMipmap(filter) {
    return (filter & (1 << 8)) === (1 << 8);
}
export function wrapRequirePot(wrap) {
    return wrap === GL_REPEAT || wrap === GL_MIRRORED_REPEAT;
}
export function isPowerOf2(n) {
    return (n != 0 && (n & (n - 1)) === 0);
}
function nearestPowerOf2(n) {
    if (isPowerOf2(n))
        return n;
    if (n % 2 === 1)
        n++;
    return Math.pow(2.0, Math.round(Math.log(n) / Math.log(2.0)));
}
export default class Image {
    constructor() {
        this.gltftype = GltfTypes.IMAGE;
    }
    async parse(gltfLoader, data) {
        if (data.uri) {
            this.uri = data.uri;
            if (this.uri.indexOf('data:') == 0) {
                this.resolvedUri = this.uri;
            }
            else {
                this.resolvedUri = gltfLoader.resolveUri(this.uri);
            }
        }
        this.mimeType = data.mimeType;
        if (data.bufferView !== undefined) {
            this.bufferView = await gltfLoader.getElement(GltfTypes.BUFFERVIEW, data.bufferView);
        }
        const blob = await this.loadImageBlob(gltfLoader.abortSignal);
        this.texImageSource = await gltfLoader.gltfIO.loadImageBlob(blob, gltfLoader.abortSignal);
    }
    async loadImageBlob(abortSignal) {
        if (this.bufferView) {
            // mimeType is guaranted here
            const arrayView = new Uint8Array(this.bufferView.buffer._bytes, this.bufferView.getByteOffset(), this.bufferView.byteLength);
            return new Blob([arrayView], { type: this.mimeType });
        }
        else {
            // assume uri is defained as uri or data uri
            const signal = createNativeSignal(abortSignal);
            const request = await fetch(this.resolvedUri, { signal });
            const blob = await request.blob();
            return blob;
        }
    }
    async setupTexture(texture, wrapS, wrapT, minFilter, magFilter) {
        const gl = texture.gl;
        let texImageSource = this.texImageSource;
        if (wrapRequirePot(wrapS) || wrapRequirePot(wrapT) || filterHasMipmap(minFilter)) {
            if (!isPowerOf2(texImageSource.width) || !isPowerOf2(texImageSource.height))
                texImageSource = await this.resizeToPOT(texImageSource, gl);
        }
        texture.fromImage(this.texImageSource);
        if (filterHasMipmap(minFilter)) {
            gl.generateMipmap(gl.TEXTURE_2D);
        }
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, magFilter);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, minFilter);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, wrapS);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, wrapT);
        return null;
    }
    async resizeToPOT(source, gl) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = nearestPowerOf2(source.width);
        canvas.height = nearestPowerOf2(source.height);
        if (source instanceof ImageData) {
            const imageBitmap = await createImageBitmap(source, 0, 0, canvas.width, canvas.height);
            context.drawImage(imageBitmap, 0, 0, canvas.width, canvas.height);
        }
        else {
            context.drawImage(source, 0, 0, canvas.width, canvas.height);
        }
        return canvas;
    }
}
