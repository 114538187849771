import { GAME_ID } from "datas";
import Module from "modules/layout/LayoutModule";
import ModuleComponent from "mixins/ModuleComponent";
import { registerGLApp } from "mixins/GLAppProvider";
import CMenu from "components/menu/menu.vue";
import CursorCustom from "components/cursor-custom/cursor-custom.vue";
import LangPanel from "components/lang-panel/lang-panel.vue";
import RotateScreen from "components/rotate-screen/rotate-screen.vue";
import ObjectFit from "components/object-fit/object-fit.vue";
import SoundButton from "components/sound-button/sound-button.vue";
import { I18n } from "core/I18n";
import gsap from 'gsap';

const _module = ModuleComponent(Module);

export default {
  _module: _module,
  mixins: [_module],
  name: 'layout',
  components: {
    CMenu,
    LangPanel,
    RotateScreen,
    CursorCustom,
    ObjectFit,
    SoundButton
  },
  computed: {
    isGame() {
      return this.$route.name === GAME_ID.GAME_1 || this.$route.name === GAME_ID.GAME_2 || this.$route.name === GAME_ID.GAME_3 || this.$route.name === GAME_ID.GAME_4;
    },

    suffix() {
      return window.innerWidth < 768 ? 'mobile' : 'desktop';
    },

    imgWidth() {
      return this.suffix === 'mobile' ? 374 : 1280;
    },

    imgHeight() {
      return this.suffix === 'mobile' ? 664 : 720;
    }

  },
  watch: {
    '$appdatas.store.pageTracking': 'onPageTrackingChange',
    '$appdatas.store.soundMuted': 'onSoundMutedChange',
    '$appdatas.store.completeGames': 'onCompleteGamesChange'
  },

  mounted() {
    this._handleVisibilityChange = this.handleVisibilityChange.bind(this);

    if (typeof document.hidden !== "undefined") {
      this.hidden = "hidden";
      this.visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      this.hidden = "msHidden";
      this.visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      this.hidden = "webkitHidden";
      this.visibilityChange = "webkitvisibilitychange";
    }

    if (typeof document.addEventListener !== "undefined" && typeof document.hidden !== "undefined") {
      document.addEventListener(this.visibilityChange, this._handleVisibilityChange, false);
    } // Add this hook for GTM


    this.onPageLoaded();
  },

  beforeDestroy() {
    document.removeEventListener(this.visibilityChange, this._handleVisibilityChange, false);
  },

  methods: {
    handleVisibilityChange() {
      if (document[this.hidden]) {
        this.getModule().setVolume(0.0);
      } else {
        const volume = this.$appdatas.store.soundMuted ? 0.0 : 1.0;
        this.getModule().setVolume(volume);
      }
    },

    onPageTrackingChange() {
      if (!dataLayer) {
        console.warn('dataLayer not initialize');
        return;
      }

      const pageData = {
        'event': 'vpv',
        'VPV': this.$appdatas.store.pageTracking,
        'localeCode': I18n.locale,
        'countryCode': I18n.country,
        'utm_source': this.$appdatas.store.utm_source,
        'utm_campaign': this.$appdatas.store.utm_campaign,
        'retail': this.$appdatas.store.isRetail
      };
      dataLayer.push(pageData);
    },

    onPageLoaded() {
      if (!dataLayer) {
        console.warn('dataLayer not initialize');
        return;
      }

      const pageData = {
        'localeCode': I18n.locale,
        'countryCode': I18n.country,
        'retail': this.$appdatas.store.isRetail
      };
      dataLayer.push(pageData);
    },

    onSoundMutedChange() {
      const volume = this.$appdatas.store.soundMuted ? 0.0 : 1.0;
      this.getModule().setVolume(volume);
      this.$appdatas.sendEvent('click', 'soundtoggle', '');
    },

    onCompleteGamesChange() {
      this.$appdatas.sendEvent('game', 'played', '', this.$appdatas.store.completeGames.length);
    }

  }
};