var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-Cursor",
      class: [_vm.activeClass, _vm.active ? "is-active" : ""]
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-Cursor-drag" }, [
      _c("div", { staticClass: "c-Cursor-drag-inner" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }