import ResourceGroup from "./resource/ResourceGroup";
import { GetAudioModule } from "core/audio/AudioModule";
import AudioResourceGroup from "core/resource/AudioResourceGroup";
export default class Module {
    constructor() {
        this.name = "";
        this._glapp = null;
        this._scene = null;
        // Observed data
        // Consumable by vue components
        this.data = {
            loaded: false
        };
    }
    init(glapp) {
        this.audio = GetAudioModule();
        this._glapp = glapp;
        // ALL resources of the module
        this.resources = new ResourceGroup(this.name + "-resources", null);
        // Resources needed before entering
        // for instance : loading screen of the module
        this.preloadedResources = new ResourceGroup(this.name + "-preloaded", this.resources);
        // Audio related resources should go there
        this.audioResources = new AudioResourceGroup(this.name + "-audio", this.audio, this.resources);
        console.log("[MODULE][" + this.name + "] init");
    }
    // Store main vue component
    registerVm(vm) {
        console.log("[MODULE][" + this.name + "] register vue component");
        this._vm = vm;
    }
    registerScene(scene) {
        this._scene = scene;
    }
    getScene() {
        return this._scene;
    }
    // Called on navigation
    // Prevent page change until resolved
    async preload() {
        await this.preloadedResources.load().whenAllLoadables();
    }
    // Effective loading of the module
    // Called within the enter func
    async load() {
        // Init scene and,
        // Load all non resourcesGrouped asset (ex: envmap, material setup, program compile)
        if (this._scene)
            await this._glapp.initScene(this._scene);
        // All resources are now registered
        // Prepare UI for loading
        if (this._vm)
            this._vm.prepareLoading();
        // LOAD ALL
        await this.resources.load().whenAllLoadables();
        this.data.loaded = true;
        this.onLoaded();
        return null;
    }
    // Called right before page change
    async release() {
        if (this._scene)
            await this._glapp.releaseScene(this._scene);
        await this.resources.unloadAll();
        // Should be unloaded by the master resources
        // await this.preloadedResources.unload();
        // See if we keep it here for instance, how to handle soundtrack
        // For now audioResources parented to module resources
        // await this.audioResources.unload();
        this.data = null;
        this._scene = null;
        this._vm = null;
        this._glapp = null;
    }
    async enter() {
        // First trigger UI enter
        // for instance loading screen
        if (this._vm)
            await this._vm.enter();
        // Load all the resources
        await this.load();
        if (this._scene) {
            this._scene.onLoaded();
            this._glapp.setActive(this._scene);
            // Called from inside the module now !!!
            // await this._scene.enter();
        }
        // Trigger hooks
        if (this._vm)
            this._vm.onLoaded();
        this.onEntered();
        return null;
    }
    async leave() {
        let _leavers = [];
        if (this._vm)
            _leavers.push(this._vm.leave());
        if (this._scene)
            _leavers.push(this._scene.leave());
        await Promise.all(_leavers);
        this.onLeaved();
        return null;
    }
    // ======
    // HOOKS
    // ======
    onLoaded() { }
    ;
    onEntered() { }
    ;
    onLeaved() { }
    ;
}
