import locales from "datas/locales-list.json"; // import locales2 from "datas/locales_list_2.json";

import { I18n } from "core/I18n";
export default {
  name: 'LangPanel',

  data() {
    return {
      locales: [],
      isActive: true
    };
  },

  computed: {
    currentCountry() {
      return I18n.locale.slice(I18n.locale.length - 2, I18n.locale.length);
    }

  },
  watch: {
    '$appdatas.store.langPanelOpen': 'onPanelChange'
  },

  created() {
    this.locales = locales;
  },

  methods: {
    getCountry(locale) {
      return locale.slice(locale.length - 2, locale.length);
    },

    onLocaleChange(lang) {
      this.$router.push({
        name: this.$router.currentRoute.name,
        params: {
          lang
        }
      });
      this.$appdatas.store.langPanelOpen = false;
    },

    onPanelChange() {
      if (!this.$appdatas.store.langPanelOpen) return;
      this.$refs.scrollableElement.scrollTop = 0;
    }

  }
};