export const iconTypes = [
    {
        type: 'GAME_1',
        file: '<svg width="26" height="27" viewBox="0 0 26 27" xmlns="http://www.w3.org/2000/svg"><g stroke-width="3.12" fill="none" fill-rule="evenodd"><path d="M2.78 24.284l10.641-10.766 10.642 10.766"/><path d="M2.78 15.108L13.421 4.34l10.642 10.767"/></g></svg>'
    },
    {
        type: 'GAME_2',
        file: '<svg width="12" height="19" viewBox="0 0 12 19" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M.2 0h3.12v18.72H.2zM6.44 0h3.12v18.72H6.44z"/></g></svg>'
    },
    {
        type: 'GAME_3',
        file: '<svg width="18" height="25" viewBox="0 0 18 25" xmlns="http://www.w3.org/2000/svg"><path d="M9.08 23.28c4.308 0 7.8-3.41 7.8-7.616 0-2.804-2.6-7.545-7.8-14.224-5.2 6.679-7.8 11.42-7.8 14.224 0 4.206 3.492 7.616 7.8 7.616z" stroke-width="1.56" fill="none" fill-rule="evenodd"/></svg>'
    },
    {
        type: 'GAME_4',
        file: '<svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg"><path d="M12.48 1.33l11.03 11.03-11.03 11.03L1.45 12.36z" stroke-width="1.56" fill="none" fill-rule="evenodd"/></svg>'
    },
    {
        type: 'retry',
        file: '<svg width="16" height="19" viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M3.06 6.04a7 7 0 103.966-1.972M9.634 1.104l-3.036 2.93 3.036 3.098"/></g></svg>'
    },
    {
        type: 'close',
        file: '<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g fill="#17233A" fill-rule="evenodd"><path d="M.929 13.657L13.657.929l1.414 1.414L2.343 15.071z"/><path d="M2.343.929l12.728 12.728-1.414 1.414L.929 2.343z"/></g></svg>'
    },
    {
        type: 'facebook',
        file: '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.315 24V11.999h3.254L18 7.863h-3.685l.005-2.07c0-1.079.1-1.657 1.623-1.657h2.034V0h-3.255c-3.91 0-5.285 2.006-5.285 5.38v2.483H7V12h2.437V24h4.878z" fill="#17233A" fill-rule="evenodd"/></svg>'
    },
    {
        type: 'twitter',
        file: '<svg width="24" height="20" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg"><path d="M11.653 5.32l.052.865-.867-.106c-3.156-.406-5.913-1.783-8.254-4.095L1.439.836l-.294.848C.52 3.573.919 5.567 2.22 6.909c.693.742.537.848-.66.406-.415-.141-.78-.247-.814-.194-.122.124.294 1.73.624 2.366.45.882 1.37 1.747 2.376 2.26l.85.405-1.006.018c-.971 0-1.006.018-.902.389.347 1.147 1.717 2.365 3.243 2.895l1.075.37-.937.566a9.702 9.702 0 01-4.647 1.306c-.78.018-1.422.088-1.422.141 0 .177 2.116 1.165 3.347 1.554 3.694 1.147 8.08.653 11.376-1.307 2.34-1.394 4.682-4.166 5.774-6.85.59-1.43 1.18-4.042 1.18-5.296 0-.812.052-.918 1.023-1.889.572-.565 1.11-1.183 1.214-1.36.173-.335.156-.335-.729-.034-1.474.53-1.682.459-.953-.336C22.769 1.754 23.41.73 23.41.43c0-.053-.26.035-.555.194-.312.177-1.006.442-1.526.6l-.937.3-.85-.582c-.468-.318-1.127-.67-1.473-.777-.885-.247-2.237-.212-3.035.07-2.168.795-3.538 2.843-3.382 5.085z" fill="#17233A" fill-rule="evenodd"/></svg>'
    },
    {
        type: 'linkedin',
        file: '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M17.57 8.498c3.104 0 5.43 2.025 5.43 6.377V23h-4.715v-7.58c0-1.905-.683-3.204-2.39-3.204-1.303 0-2.079.876-2.42 1.722-.124.303-.155.725-.155 1.149V23H8.604s.063-12.84 0-14.17h4.716v2.008c.626-.965 1.747-2.34 4.25-2.34zM5.995 8.83V23H1.28V8.83h4.715zM3.668 2C5.28 2 6.274 3.058 6.305 4.447c0 1.36-1.024 2.45-2.668 2.45h-.03C2.023 6.897 1 5.807 1 4.447 1 3.058 2.055 2 3.668 2z" fill="#17233A" fill-rule="evenodd"/></svg>'
    },
    {
        type: 'youtube',
        file: '<svg width="26" height="19" viewBox="0 0 26 19" xmlns="http://www.w3.org/2000/svg"><path d="M13.036-.001c.44 0 5.585.012 9.066.274.507.062 1.616.067 2.604 1.143.78.82 1.034 2.682 1.034 2.682S26 6.284 26 8.47v2.05c0 2.187-.26 4.372-.26 4.372s-.254 1.862-1.034 2.682c-.988 1.076-2.097 1.081-2.604 1.144-3.322.25-8.166.279-8.983.282h-.129c-.288-.003-6.796-.068-8.83-.271-.579-.113-1.878-.079-2.867-1.155-.78-.82-1.033-2.682-1.033-2.682S0 12.707 0 10.52V8.244c.018-2.108.26-4.146.26-4.146s.254-1.862 1.033-2.682C2.283.34 3.39.335 3.898.273c3.48-.262 8.625-.274 9.066-.274zm-2.721 5.414v7.59l7.025-3.782-7.025-3.808z" fill="#17233A" fill-rule="evenodd"/></svg>'
    },
    {
        type: 'pinterest',
        file: '<svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"><path d="M0 14c0 5.93 3.69 10.997 8.897 13.037-.122-1.107-.232-2.806.05-4.017.253-1.093 1.641-6.958 1.641-6.958s-.419-.84-.419-2.079c0-1.947 1.127-3.4 2.534-3.4 1.193 0 1.77.897 1.77 1.972 0 1.202-.764 2.997-1.16 4.662-.329 1.395.7 2.53 2.074 2.53 2.488 0 4.401-2.625 4.401-6.412 0-3.352-2.408-5.695-5.848-5.695-3.982 0-6.32 2.988-6.32 6.075 0 1.203.461 2.494 1.04 3.196.115.139.131.26.098.402-.106.441-.342 1.393-.389 1.586-.061.257-.203.311-.47.19-1.747-.815-2.84-3.373-2.84-5.425 0-4.416 3.21-8.473 9.25-8.473 4.857 0 8.631 3.461 8.631 8.086 0 4.825-3.043 8.708-7.264 8.708-1.42 0-2.753-.738-3.21-1.608l-.872 3.328c-.316 1.216-1.17 2.74-1.739 3.67 1.309.407 2.7.625 4.145.625 7.732 0 14-6.268 14-14 0-7.734-6.268-14-14-14S0 6.266 0 14z" fill="#17233A" fill-rule="evenodd"/></svg>'
    },
    {
        type: 'messenger',
        file: '<svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"><path d="M13.8 0C6.179 0 0 5.721 0 12.778c0 4.021 2.007 7.608 5.143 9.95V27.6l4.699-2.578c1.253.346 2.583.533 3.958.533 7.621 0 13.8-5.72 13.8-12.777C27.6 5.72 21.421 0 13.8 0zm1.372 17.207l-3.514-3.747L4.8 17.207 12.343 9.2l3.6 3.748L22.714 9.2l-7.543 8.007z" fill="#17233A" fill-rule="evenodd"/></svg>'
    },
    {
        type: 'wechat',
        file: '<svg width="29" height="24" viewBox="0 0 29 24" xmlns="http://www.w3.org/2000/svg"><path d="M25.802 21.03c1.83-1.326 2.998-3.286 2.998-5.465 0-3.991-3.884-7.227-8.675-7.227-4.79 0-8.675 3.236-8.675 7.227 0 3.993 3.885 7.229 8.675 7.229.99 0 1.945-.14 2.832-.396l.255-.038c.166 0 .318.051.46.133l1.9 1.097.167.054c.16 0 .289-.13.289-.29l-.047-.21-.391-1.459-.03-.185c0-.194.096-.366.242-.47zM10.41.356C4.66.356 0 4.24 0 9.031c0 2.614 1.402 4.967 3.596 6.557a.69.69 0 01.292.565l-.036.221-.47 1.75-.056.253c0 .192.156.348.347.348l.202-.065 2.279-1.316c.17-.099.351-.16.552-.16l.306.046a12.31 12.31 0 003.398.476l.571-.014a6.703 6.703 0 01-.349-2.126c0-4.369 4.25-7.911 9.493-7.911l.565.014C19.907 3.526 15.602.356 10.41.356zm6.823 14.054a1.156 1.156 0 11.002-2.313 1.156 1.156 0 01-.002 2.313zm5.784 0a1.157 1.157 0 110-2.314 1.157 1.157 0 010 2.314zM6.94 7.643a1.387 1.387 0 110-2.774 1.387 1.387 0 010 2.774zm6.94 0a1.387 1.387 0 11-.001-2.774 1.387 1.387 0 010 2.774z" fill="#17233A" fill-rule="evenodd"/></svg>'
    },
    {
        type: 'line',
        file: '<svg width="28" height="27" viewBox="0 0 28 27" xmlns="http://www.w3.org/2000/svg"><path d="M27.6 11.85c0-6.175-6.19-11.2-13.8-11.2C6.192.65 0 5.676 0 11.85c0 5.536 4.91 10.173 11.541 11.049.45.096 1.06.296 1.216.68.14.349.09.895.045 1.248l-.197 1.181c-.06.349-.278 1.364 1.195.744 1.473-.62 7.948-4.679 10.842-8.012 2-2.193 2.958-4.419 2.958-6.89zM5.764 8.865c.148 0 .269.121.269.27v4.775h2.63c.148 0 .268.12.268.268v.969c0 .148-.12.269-.268.269H4.795a.267.267 0 01-.185-.075v-.001l-.003-.002-.001-.002-.004-.003a.269.269 0 01-.074-.185V9.134c0-.148.12-.269.268-.269h.968zm17.24 0c.149 0 .269.121.269.27v.968c0 .148-.12.269-.268.269h-2.63v1.015h2.63c.148 0 .268.121.268.27v.968c0 .148-.12.269-.268.269h-2.63v1.016h2.63c.148 0 .268.121.268.268v.969c0 .148-.12.269-.268.269h-3.868a.27.27 0 01-.186-.075l-.004-.005-.003-.003a.27.27 0 01-.076-.185V9.134c0-.07.029-.136.075-.185l.004-.004.004-.004a.27.27 0 01.186-.076h3.868zm-12.008.002c.149 0 .27.12.27.267v6.014a.27.27 0 01-.27.268h-.968a.27.27 0 01-.27-.268V9.134c0-.148.122-.268.27-.268h.968zm2.446 0h.023l.015.002h.012l.015.004.009.004.016.004.01.004.014.007.008.003.015.008.008.005.014.01.007.005.015.012.005.005.016.016.002.003.02.026 2.756 3.721V9.134c0-.148.12-.268.27-.268h.966c.15 0 .27.12.27.268v6.014c0 .147-.12.268-.27.268h-.967l-.069-.01h-.005L16.6 15.4l-.008-.004-.014-.004-.012-.007-.008-.004-.016-.01-.003-.002-.069-.067-2.759-3.726v3.572c0 .147-.12.268-.269.268h-.967a.27.27 0 01-.269-.268V9.134c0-.148.12-.268.27-.268h.966z" fill="#17233A" fill-rule="evenodd"/></svg>'
    },
    {
        type: 'whatsapp',
        file: '<svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"><path d="M.066 27.6l1.94-7.087A13.647 13.647 0 01.18 13.675C.184 6.135 6.319 0 13.857 0a13.59 13.59 0 019.675 4.011 13.598 13.598 0 014.002 9.676c-.003 7.54-6.138 13.676-13.676 13.676a13.685 13.685 0 01-6.542-1.665L.066 27.6zm20.681-10.662c-.085-.142-.313-.227-.655-.399-.342-.171-2.022-.998-2.336-1.112-.313-.114-.54-.171-.77.172-.227.341-.883 1.112-1.082 1.34-.198.227-.399.256-.74.084-.342-.17-1.443-.53-2.749-1.696-1.015-.906-1.702-2.025-1.9-2.368-.2-.341-.021-.526.149-.697.154-.152.341-.399.513-.599.174-.197.23-.34.345-.569.114-.228.057-.428-.029-.6-.086-.17-.77-1.852-1.053-2.536-.279-.666-.56-.576-.77-.587l-.655-.011c-.228 0-.598.085-.91.428-.314.342-1.197 1.168-1.197 2.85 0 1.683 1.225 3.308 1.395 3.536.171.227 2.41 3.68 5.837 5.16.816.352 1.453.562 1.948.72.82.26 1.564.223 2.153.135.657-.097 2.022-.826 2.307-1.625.285-.799.285-1.483.2-1.626z" fill="#17233A" fill-rule="nonzero"/></svg>'
    },
    {
        type: 'weibo',
        file: '<svg width="30" height="24" viewBox="0 0 30 24" xmlns="http://www.w3.org/2000/svg"><g fill="#17233A" fill-rule="nonzero"><path d="M29.618 7.123c.127 1.652-.152 3.631-1.243 3.728-1.78.16-.852-1.802-.828-2.983.065-3.422-2.857-5.8-5.718-5.8-.81 0-2.694.55-2.404-1.16.13-.754.77-.743 1.409-.828 4.812-.642 8.446 2.669 8.784 7.043zM21.746 11.1c2.07 1.132 4.555 1.696 4.143 5.055-.099.805-.586 1.88-1.077 2.568-3.497 4.895-14.046 6.953-20.633 3.232-2.21-1.249-4.495-3.074-4.143-6.713.304-3.13 2.41-5.556 4.475-7.622 1.971-1.972 4.05-3.515 6.878-4.226 3.068-.771 3.975 1.787 3.148 4.309 1.777-.12 5.544-2.103 7.21-.166.733.854.452 2.383 0 3.563zm-2.237 7.954c.663-.751 1.329-1.897 1.325-3.23-.009-4.118-5.197-5.64-9.28-5.304-2.233.183-3.736.655-5.303 1.575-1.28.75-2.777 1.973-3.149 3.81-.838 4.134 3.658 6.077 6.96 6.298 3.815.257 7.634-1.09 9.447-3.149z"/><path d="M25.557 7.123c.174 1.261-.187 2.355-.911 2.403-1.207.08-.756-.827-.83-1.906-.045-.665-.596-1.432-1.076-1.657-.943-.442-2.403.321-2.403-.994 0-.977.847-.876 1.326-.912 2.098-.16 3.65 1.286 3.894 3.066zM15.863 15.409c1.765 5.336-7.433 7.817-9.115 3.231-1.125-3.066 1.577-5.505 4.392-5.8 2.403-.251 4.194.974 4.723 2.569zm-4.226.912c.374.648 1.236.182 1.16-.331-.09-.595-1.168-.507-1.16.33zm-1.492 2.817c1.547-.368 1.754-3.28-.58-2.734-1.96.458-1.582 3.248.58 2.734z"/></g></svg>'
    },
    {
        type: 'email',
        file: '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.37 12.203L2.208 4.777A1 1 0 012.838 3h18.325a1 1 0 01.63 1.777l-9.163 7.426a1 1 0 01-1.26 0zm0 2.574L1.63 6.883A1 1 0 000 7.66V20a1 1 0 001 1h22a1 1 0 001-1V7.66a1 1 0 00-1.63-.777l-9.74 7.894a1 1 0 01-1.26 0z" fill="#17233A" fill-rule="nonzero"/></svg>'
    },
    {
        type: 'flask',
        file: '<svg width="10" height="22" viewBox="0 0 10 22" xmlns="http://www.w3.org/2000/svg"><g stroke="#17233A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M1.08 19.973a1.962 1.962 0 001.388 1.442M2.468 21.433c.138.038.28.027.415-.03h4.272M2.692 4.784h1.044M3.936 4.584V2.111M3.736 4.768a.183.183 0 00.183-.184M2.186 9.072a1.322 1.322 0 00-1.106.922M2.186 9.072a.366.366 0 00.323-.364M7.57 21.415a1.961 1.961 0 001.387-1.443M7.155 21.402a.622.622 0 00.414.032M7.345 4.784H6.301M6.135 4.584V2.111M6.118 4.584c0 .101.082.184.183.184M8.957 9.989a1.327 1.327 0 00-1.106-.917M7.529 8.708c0 .185.138.342.322.364M6.118 2.111a1.1 1.1 0 10-2.199 0M2.526 4.951v3.757M2.692 4.768a.183.183 0 00-.183.183M7.545 4.951v3.757M7.529 4.95a.183.183 0 00-.184-.182M1.097 19.973V9.994M8.974 19.972V9.989"/></g></svg>'
    }
];
