import Vue from "vue"; // Module factory coming from vue component

export default function (appModule) {
  let mod;
  return {
    async releaseModule() {
      await mod.release();
      mod = null;
    },

    instantiateModule() {
      mod = new appModule();
    },

    beforeCreate() {
      mod.data = Vue.observable(mod.data);
    },

    created() {
      this.$data.moduledata = mod.data;
    },

    data: function () {
      return mod.data;
    },

    mounted() {
      mod.registerVm(this);
      mod.enter();
    },

    methods: {
      // Get Main attached module
      getModule() {
        return mod;
      },

      // Get loaded or preloaded resource
      getResource(param) {
        let res;

        try {
          res = mod.resources.get(param);
          return res;
        } catch (e) {
          if (mod.preloadedResources.hasResource(param)) {
            return mod.preloadedResources.get(param);
          } else {
            console.warn(e);
            return {};
          }
        }
      },

      // Hooks
      // =====
      prepareLoading() {// Loader UI init
        // All resources are ready for loading
      },

      onLoaded() {// All resources as been loaded
      },

      async leave() {
        return null;
      },

      async enter() {
        // First enter on the component
        return null;
      }

    }
  };
}