import KTXParser from "./KTXParser";
import { TextureDataType } from "@webgl/assets/TextureData";
import { TextureType } from "nanogl/texture-base";
export default class TextureCodecBaseKtx {
    constructor() {
        this.parser = new KTXParser();
    }
    isSupported(extensions) {
        return false;
    }
    decodeLod(source, lod, extensions) {
        const requestLod = source.lods[lod];
        const image = this.parser.parse(requestLod.buffers[0]);
        const mips = image.surfaces[0].map(l => {
            return {
                width: image.width,
                height: image.height,
                data: l
            };
        });
        const datas = {
            datatype: TextureDataType.RAW_COMPRESSED,
            textureType: TextureType.TEXTURE_2D,
            width: image.width,
            height: image.height,
            internalformat: image.internalFormat,
            format: image.format,
            type: image.type,
            sources: [{
                    surfaces: [mips]
                }]
        };
        source.datas = datas;
        return null;
    }
    decodeCube(source, extensions) {
        throw new Error("Method not implemented.");
    }
}
