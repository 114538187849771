import Module from "core/Module";
import { ImageResource } from "core/resource/ImageResource";
import { GetAudioModule } from "core/audio/AudioModule";
import datas from 'datas';
import Audio from './Audio';
export default class LayoutModule extends Module {
    constructor() {
        super();
        this.name = "Layout";
        console.log("[MODULE][Layout] instantiate");
    }
    init(glapp) {
        super.init(glapp);
        // Preload
        let name = (window.innerWidth >= 768) ? 'desktop' : 'mobile';
        new ImageResource("bg-stars", require('assets/img/bg-stars-' + name + '.jpg'), this.preloadedResources);
        new ImageResource("hub-preview", require('assets/img/hub-preview-' + name + '.jpg'), this.preloadedResources);
        new ImageResource("menu-product", require('assets/img/menu-purchase.png'), this.preloadedResources);
        new ImageResource("menu-push", require('assets/img/menu-push.jpg'), this.preloadedResources);
        new ImageResource("intro-thumbnail", require('assets/img/intro_thumbnail.png'), this.preloadedResources);
        new ImageResource("hub-thumbnail", require('assets/img/hub_thumbnail.png'), this.preloadedResources);
        new ImageResource("game-thumbnail-1", require('assets/img/game_1_thumbnail.png'), this.preloadedResources);
        new ImageResource("game-thumbnail-2", require('assets/img/game_2_thumbnail.png'), this.preloadedResources);
        new ImageResource("game-thumbnail-3", require('assets/img/game_3_thumbnail.png'), this.preloadedResources);
        new ImageResource("game-thumbnail-4", require('assets/img/game_4_thumbnail.png'), this.preloadedResources);
        datas.audioLayout = new Audio(this.audioResources);
        this.audioModule = GetAudioModule();
        // this.audioResources.
        // this.gain.gain.value = 0.0;
    }
    onLoaded() {
    }
    setVolume(volume = 0.0) {
        this.audioModule.masterNode.gain.value = volume;
    }
}
/////////////
// prevent hot reload error in ModuleComponent
if (module.hot)
    module.hot.decline();
//////////
