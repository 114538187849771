/**
 * Module for glTF 2.0 Interface
 */
var Gltf2;
(function (Gltf2) {
    // PreParse Additions
    /**
     * The datatype of the components in the attribute
     */
    let AccessorComponentType;
    (function (AccessorComponentType) {
        /**
         * Byte
         */
        AccessorComponentType[AccessorComponentType["BYTE"] = 5120] = "BYTE";
        /**
         * Unsigned Byte
         */
        AccessorComponentType[AccessorComponentType["UNSIGNED_BYTE"] = 5121] = "UNSIGNED_BYTE";
        /**
         * Short
         */
        AccessorComponentType[AccessorComponentType["SHORT"] = 5122] = "SHORT";
        /**
         * Unsigned Short
         */
        AccessorComponentType[AccessorComponentType["UNSIGNED_SHORT"] = 5123] = "UNSIGNED_SHORT";
        /**
         * Unsigned Int
         */
        AccessorComponentType[AccessorComponentType["UNSIGNED_INT"] = 5125] = "UNSIGNED_INT";
        /**
         * Float
         */
        AccessorComponentType[AccessorComponentType["FLOAT"] = 5126] = "FLOAT";
    })(AccessorComponentType = Gltf2.AccessorComponentType || (Gltf2.AccessorComponentType = {}));
    /**
     * Specifies if the attirbute is a scalar, vector, or matrix
     */
    let AccessorType;
    (function (AccessorType) {
        /**
         * Scalar
         */
        AccessorType["SCALAR"] = "SCALAR";
        /**
         * Vector2
         */
        AccessorType["VEC2"] = "VEC2";
        /**
         * Vector3
         */
        AccessorType["VEC3"] = "VEC3";
        /**
         * Vector4
         */
        AccessorType["VEC4"] = "VEC4";
        /**
         * Matrix2x2
         */
        AccessorType["MAT2"] = "MAT2";
        /**
         * Matrix3x3
         */
        AccessorType["MAT3"] = "MAT3";
        /**
         * Matrix4x4
         */
        AccessorType["MAT4"] = "MAT4";
    })(AccessorType = Gltf2.AccessorType || (Gltf2.AccessorType = {}));
    /**
     * The name of the node's TRS property to modify, or the weights of the Morph Targets it instantiates
     */
    let AnimationChannelTargetPath;
    (function (AnimationChannelTargetPath) {
        /**
         * Translation
         */
        AnimationChannelTargetPath["TRANSLATION"] = "translation";
        /**
         * Rotation
         */
        AnimationChannelTargetPath["ROTATION"] = "rotation";
        /**
         * Scale
         */
        AnimationChannelTargetPath["SCALE"] = "scale";
        /**
         * Weights
         */
        AnimationChannelTargetPath["WEIGHTS"] = "weights";
    })(AnimationChannelTargetPath = Gltf2.AnimationChannelTargetPath || (Gltf2.AnimationChannelTargetPath = {}));
    /**
     * Interpolation algorithm
     */
    let AnimationSamplerInterpolation;
    (function (AnimationSamplerInterpolation) {
        /**
         * The animated values are linearly interpolated between keyframes
         */
        AnimationSamplerInterpolation["LINEAR"] = "LINEAR";
        /**
         * The animated values remain constant to the output of the first keyframe, until the next keyframe
         */
        AnimationSamplerInterpolation["STEP"] = "STEP";
        /**
         * The animation's interpolation is computed using a cubic spline with specified tangents
         */
        AnimationSamplerInterpolation["CUBICSPLINE"] = "CUBICSPLINE";
    })(AnimationSamplerInterpolation = Gltf2.AnimationSamplerInterpolation || (Gltf2.AnimationSamplerInterpolation = {}));
    /**
     * A camera's projection.  A node can reference a camera to apply a transform to place the camera in the scene
     */
    let CameraType;
    (function (CameraType) {
        /**
         * A perspective camera containing properties to create a perspective projection matrix
         */
        CameraType["PERSPECTIVE"] = "perspective";
        /**
         * An orthographic camera containing properties to create an orthographic projection matrix
         */
        CameraType["ORTHOGRAPHIC"] = "orthographic";
    })(CameraType = Gltf2.CameraType || (Gltf2.CameraType = {}));
    /**
     * The mime-type of the image
     */
    let ImageMimeType;
    (function (ImageMimeType) {
        /**
         * JPEG Mime-type
         */
        ImageMimeType["JPEG"] = "image/jpeg";
        /**
         * PNG Mime-type
         */
        ImageMimeType["PNG"] = "image/png";
    })(ImageMimeType = Gltf2.ImageMimeType || (Gltf2.ImageMimeType = {}));
    /**
     * The alpha rendering mode of the material
     */
    let MaterialAlphaMode;
    (function (MaterialAlphaMode) {
        /**
         * The alpha value is ignored and the rendered output is fully opaque
         */
        MaterialAlphaMode["OPAQUE"] = "OPAQUE";
        /**
         * The rendered output is either fully opaque or fully transparent depending on the alpha value and the specified alpha cutoff value
         */
        MaterialAlphaMode["MASK"] = "MASK";
        /**
         * The alpha value is used to composite the source and destination areas. The rendered output is combined with the background using the normal painting operation (i.e. the Porter and Duff over operator)
         */
        MaterialAlphaMode["BLEND"] = "BLEND";
    })(MaterialAlphaMode = Gltf2.MaterialAlphaMode || (Gltf2.MaterialAlphaMode = {}));
    /**
     * The type of the primitives to render
     */
    let MeshPrimitiveMode;
    (function (MeshPrimitiveMode) {
        /**
         * Points
         */
        MeshPrimitiveMode[MeshPrimitiveMode["POINTS"] = 0] = "POINTS";
        /**
         * Lines
         */
        MeshPrimitiveMode[MeshPrimitiveMode["LINES"] = 1] = "LINES";
        /**
         * Line Loop
         */
        MeshPrimitiveMode[MeshPrimitiveMode["LINE_LOOP"] = 2] = "LINE_LOOP";
        /**
         * Line Strip
         */
        MeshPrimitiveMode[MeshPrimitiveMode["LINE_STRIP"] = 3] = "LINE_STRIP";
        /**
         * Triangles
         */
        MeshPrimitiveMode[MeshPrimitiveMode["TRIANGLES"] = 4] = "TRIANGLES";
        /**
         * Triangle Strip
         */
        MeshPrimitiveMode[MeshPrimitiveMode["TRIANGLE_STRIP"] = 5] = "TRIANGLE_STRIP";
        /**
         * Triangle Fan
         */
        MeshPrimitiveMode[MeshPrimitiveMode["TRIANGLE_FAN"] = 6] = "TRIANGLE_FAN";
        MeshPrimitiveMode[MeshPrimitiveMode["DEFAULT"] = 4] = "DEFAULT";
    })(MeshPrimitiveMode = Gltf2.MeshPrimitiveMode || (Gltf2.MeshPrimitiveMode = {}));
    /**
     * Magnification filter.  Valid values correspond to WebGL enums: 9728 (NEAREST) and 9729 (LINEAR)
     */
    let TextureMagFilter;
    (function (TextureMagFilter) {
        /**
         * Nearest
         */
        TextureMagFilter[TextureMagFilter["NEAREST"] = 9728] = "NEAREST";
        /**
         * Linear
         */
        TextureMagFilter[TextureMagFilter["LINEAR"] = 9729] = "LINEAR";
    })(TextureMagFilter = Gltf2.TextureMagFilter || (Gltf2.TextureMagFilter = {}));
    /**
     * Minification filter.  All valid values correspond to WebGL enums
     */
    let TextureMinFilter;
    (function (TextureMinFilter) {
        /**
         * Nearest
         */
        TextureMinFilter[TextureMinFilter["NEAREST"] = 9728] = "NEAREST";
        /**
         * Linear
         */
        TextureMinFilter[TextureMinFilter["LINEAR"] = 9729] = "LINEAR";
        /**
         * Nearest Mip-Map Nearest
         */
        TextureMinFilter[TextureMinFilter["NEAREST_MIPMAP_NEAREST"] = 9984] = "NEAREST_MIPMAP_NEAREST";
        /**
         * Linear Mipmap Nearest
         */
        TextureMinFilter[TextureMinFilter["LINEAR_MIPMAP_NEAREST"] = 9985] = "LINEAR_MIPMAP_NEAREST";
        /**
         * Nearest Mipmap Linear
         */
        TextureMinFilter[TextureMinFilter["NEAREST_MIPMAP_LINEAR"] = 9986] = "NEAREST_MIPMAP_LINEAR";
        /**
         * Linear Mipmap Linear
         */
        TextureMinFilter[TextureMinFilter["LINEAR_MIPMAP_LINEAR"] = 9987] = "LINEAR_MIPMAP_LINEAR";
    })(TextureMinFilter = Gltf2.TextureMinFilter || (Gltf2.TextureMinFilter = {}));
    /**
     * S (U) wrapping mode.  All valid values correspond to WebGL enums
     */
    let TextureWrapMode;
    (function (TextureWrapMode) {
        /**
         * Clamp to Edge
         */
        TextureWrapMode[TextureWrapMode["CLAMP_TO_EDGE"] = 33071] = "CLAMP_TO_EDGE";
        /**
         * Mirrored Repeat
         */
        TextureWrapMode[TextureWrapMode["MIRRORED_REPEAT"] = 33648] = "MIRRORED_REPEAT";
        /**
         * Repeat
         */
        TextureWrapMode[TextureWrapMode["REPEAT"] = 10497] = "REPEAT";
    })(TextureWrapMode = Gltf2.TextureWrapMode || (Gltf2.TextureWrapMode = {}));
})(Gltf2 || (Gltf2 = {}));
export default Gltf2;
