//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import shareListUrl from "datas/share_list.json";
import shareList from "./share.json";
import uiIconVue from '../ui-icon/ui-icon.vue';
export default {
  data() {
    return {
      shareLoaded: false,
      shareList: shareList
    };
  },

  props: {
    url: {
      type: String,
      required: true
    },
    shortUrl: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    imageSrc: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    username: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    gameId: {
      type: String,
      default: ''
    }
  },
  components: {
    "ui-icon": uiIconVue
  },

  mounted() {
    this.setButtons();
  },

  methods: {
    setButtons() {
      if (!this.$appdatas.store.sharethisLoaded || this.shareLoaded) return;
      this.shareLoaded = true;
      this.$nextTick(() => {
        const $buttons = this.$el.querySelectorAll('.t-link');

        for (let index = 0; index < $buttons.length; index++) {
          const $button = $buttons[index];
          $button.removeEventListener('click', this.onShare);
          $button.addEventListener('click', this.onShare);
        }
      });
    },

    onShare(e) {
      const $button = e.currentTarget;
      const network = $button.getAttribute("data-network");
      let url = $button.getAttribute("data-url");
      url += '?utm_source=' + network + '&utm_campaign=ANRGamingExperience_Share_' + (network.charAt(0).toUpperCase() + network.slice(1));
      url = shareListUrl[url] ? shareListUrl[url] : url;
      const options = {
        description: $button.getAttribute("data-description"),
        image: $button.getAttribute("data-image"),
        message: $button.getAttribute("data-message"),
        network,
        short_url: $button.getAttribute("data-short-url"),
        title: $button.getAttribute("data-description"),
        url,
        username: $button.getAttribute("data-username")
      };

      if (window.__sharethis__) {
        try {
          if (network == "email") {
            window.location.href = "mailto:?subject=" + $button.getAttribute("data-title") + "&body=" + $button.getAttribute("data-description") + "%0D%0A%0D%0A" + encodeURIComponent(url);
          } else {
            window.__sharethis__.share(options);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        console.warn('Sharethis was not loaded, maybe it was blocked by your add blocker ?');
      }

      this.$appdatas.sendEvent('share', $button.getAttribute("data-network"), this.gameId);
    }

  },
  watch: {
    '$appdatas.store.sharethisLoaded': function (isShareActive) {
      if (!isShareActive) return;
      this.setButtons();
    }
  }
};