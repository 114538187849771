var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "samples-menu" }, [
    _c("p", [
      _vm._v("\n        Samples :\n        "),
      _c(
        "select",
        {
          ref: "samplesSelect",
          attrs: { name: "samples-menu", id: "" },
          on: { change: _vm.onInputChange }
        },
        [
          _c("option", { attrs: { value: "/", selected: "" } }, [
            _vm._v("none")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "/samples/triangle" } }, [
            _vm._v("hello triangle")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "/samples/particles" } }, [
            _vm._v("particles")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "/samples/gltf" } }, [_vm._v("gltf")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "/samples/scene-graph" } }, [
            _vm._v("scene-graph")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "/samples/ibl-env" } }, [
            _vm._v("ibl-env")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "/samples/skinning" } }, [
            _vm._v("skinning")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "/samples/postprocess" } }, [
            _vm._v("postprocess")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "/samples/textures" } }, [
            _vm._v("textures")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "/samples/audio" } }, [
            _vm._v("audio")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "/samples/loading" } }, [
            _vm._v("loading")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "/samples/dummy" } }, [
            _vm._v("dummy")
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }