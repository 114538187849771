export default {
  data: function () {
    return {
      isOpen: false,
      isForced: false
    };
  },
  methods: {
    open() {
      this.isForced = false;
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    forceClose() {
      this.isForced = true;
      this.isOpen = false;
    }

  }
};