var GltfTypes;
(function (GltfTypes) {
    GltfTypes["ACCESSOR"] = "accessors";
    GltfTypes["ACCESSOR_SPARSE"] = "sparse";
    GltfTypes["ACCESSOR_SPARSE_INDICES"] = "sparseIndices";
    GltfTypes["ACCESSOR_SPARSE_VALUES"] = "sparseValues";
    GltfTypes["ANIMATION"] = "animations";
    GltfTypes["ANIMATION_SAMPLER"] = "animationSamplers";
    GltfTypes["ANIMATION_CHANNEL"] = "animationChannels";
    GltfTypes["ASSET"] = "asset";
    GltfTypes["BUFFER"] = "buffers";
    GltfTypes["BUFFERVIEW"] = "bufferViews";
    GltfTypes["CAMERA"] = "cameras";
    GltfTypes["IMAGE"] = "images";
    GltfTypes["MATERIAL"] = "materials";
    GltfTypes["MESH"] = "meshes";
    GltfTypes["NODE"] = "nodes";
    GltfTypes["NORMAL_TEXTURE_INFO"] = "normalTextureInfo";
    GltfTypes["OCCLUSION_TEXTURE_INFO"] = "occlusionTextureInfo";
    GltfTypes["PRIMITIVE"] = "primitives";
    GltfTypes["SAMPLER"] = "samplers";
    GltfTypes["SCENE"] = "scenes";
    GltfTypes["SKIN"] = "skins";
    GltfTypes["TEXTURE"] = "textures";
    GltfTypes["TEXTURE_INFO"] = "textureInfo";
})(GltfTypes || (GltfTypes = {}));
;
export default GltfTypes;
