const DEFAULT_DIR = '/assets/audio';
let _assetsDir = DEFAULT_DIR;
export default {
    /**
     * @param {string} path
     * @return {string} prefixed path
     */
    asset_url(path) {
        return _assetsDir + '/' + path;
    },
    setBaseDir(dir = DEFAULT_DIR) {
        _assetsDir = dir;
    }
};
