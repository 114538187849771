export function cancellablePromise(p, signal) {
    return new Promise((resolve, reject) => {
        signal.addEventListener('abort', (r) => reject(r));
        p.then(resolve, reject);
    });
}
let _createNativeSignal;
if (window.AbortController !== undefined) {
    _createNativeSignal = (signal) => {
        const ctrl = new AbortController();
        signal.addEventListener('abort', (r) => ctrl.abort());
        return ctrl.signal;
    };
}
else {
    _createNativeSignal = (signal) => undefined;
}
export const createNativeSignal = _createNativeSignal;
export function isAbortError(e) {
    return e.name === 'AbortError';
}
