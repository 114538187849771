import GLApp from "webgl/GLApp";
import Vue, { VueConstructor } from "vue";
import { CATCH_ASYNC } from "../../utils/Async";
export default {
  props: {
    quality: {
      type: String,
      default: "auto",
      validator: v => ["high", "low", "auto"].indexOf(v) !== -1
    },
    assetsUrl: String,
    ilayer: Object
  },
  data: () => ({
    loaded: false,
    loadProgress: 0
  }),

  mounted() {
    this.glapp = new GLApp({
      assetsUrl: this.assetsUrl,
      canvas: this.$el,
      ilayer: this.$el,
      quality: this.quality
    });
    this.glapp.play(); // CATCH_ASYNC(this.glapp.load().then(this.onLoaded));
  }

};