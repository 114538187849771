var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-Layout" },
    [
      _vm.loaded
        ? _c(
            "object-fit",
            {
              staticClass: "c-Layout-hubPreview",
              class: { "is-active": _vm.$appdatas.store.showHubPreview },
              attrs: { width: _vm.imgWidth, height: _vm.imgHeight }
            },
            [
              _c("img", {
                staticClass: "js-object-fit c-Layout-hubPreview-img",
                attrs: { src: this.getResource("hub-preview").src, alt: "" }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("router-view") : _vm._e(),
      _vm._v(" "),
      _vm.isGame
        ? _c(
            "div",
            { staticClass: "c-Layout-hubLink" },
            [
              _c(
                "router-link",
                {
                  staticClass: "t-btn t-link--bl",
                  attrs: {
                    to: { name: "hub", params: { lang: _vm.currentLocale } }
                  }
                },
                [_vm._v(_vm._s(_vm.t("common.cta_hub")))]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c("c-menu", {
            attrs: {
              "product-img": this.getResource("menu-product"),
              "push-img": this.getResource("menu-push"),
              introThumbnail: this.getResource("intro-thumbnail"),
              hubThumbnail: this.getResource("hub-thumbnail"),
              "game-thumbnails": [
                this.getResource("game-thumbnail-1"),
                this.getResource("game-thumbnail-2"),
                this.getResource("game-thumbnail-3"),
                this.getResource("game-thumbnail-4")
              ]
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "c-Layout-burger t-btn",
          class: { "is-active": _vm.$appdatas.store.menuOpen },
          attrs: { "aria-label": "Toggle Menu", type: "button" },
          on: {
            click: function($event) {
              _vm.$appdatas.store.menuOpen = !_vm.$appdatas.store.menuOpen
            }
          }
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c("sound-button"),
      _vm._v(" "),
      _c("lang-panel"),
      _vm._v(" "),
      _vm.loaded
        ? _c("rotate-screen", {
            attrs: { "bg-img": this.getResource("bg-stars") }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("cursor-custom")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-Layout-burger-inner" }, [
      _c("div", { staticClass: "c-Layout-burger-line" }),
      _vm._v(" "),
      _c("div", { staticClass: "c-Layout-burger-line" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }