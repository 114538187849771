import TextureCodecBaseKtx from "@webgl/assets/TextureCodecBaseKtx";
export default class TextureCodecPvr extends TextureCodecBaseKtx {
    constructor() {
        super(...arguments);
        this.name = 'pvr';
    }
    isSupported(extensions) {
        return extensions.pvr != null;
    }
}
