var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "v-menu" }, [
    _c(
      "div",
      {
        staticClass: "v-menu__nav",
        on: {
          click: function($event) {
            return _vm.requestNav("/intro")
          }
        }
      },
      [_vm._v("Intro")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "v-menu__nav",
        on: {
          click: function($event) {
            return _vm.requestNav("/hub")
          }
        }
      },
      [_vm._v("Hub")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "v-menu__nav",
        on: {
          click: function($event) {
            return _vm.requestNav("/game-1")
          }
        }
      },
      [_vm._v("Game 1")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "v-menu__nav",
        on: {
          click: function($event) {
            return _vm.requestNav("/game-2")
          }
        }
      },
      [_vm._v("Game 2")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "v-menu__nav",
        on: {
          click: function($event) {
            return _vm.requestNav("/game-3")
          }
        }
      },
      [_vm._v("Game 3")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "v-menu__nav",
        on: {
          click: function($event) {
            return _vm.requestNav("/game-4")
          }
        }
      },
      [_vm._v("Game 4")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }