import ObjectFit from "components/object-fit/object-fit.vue";
export default {
  name: 'RotateScreen',
  components: {
    ObjectFit
  },
  props: {
    bgImg: {
      type: HTMLImageElement | Object
    }
  }
};