var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$appdatas.store.isRetail
    ? _c(
        "div",
        {
          staticClass: "c-LangSelector",
          class: {
            "is-dark": _vm.dark,
            "is-retail": _vm.$appdatas.store.isRetail
          }
        },
        [
          _c("p", { staticClass: "c-LangSelector-label t-text--sm" }, [
            _vm._v(_vm._s(_vm.t("common.your_location")))
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "t-btn",
              class: [_vm.dark ? "t-link--ud" : "t-link--ul"],
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  _vm.$appdatas.store.langPanelOpen = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.t("common.country_name")))]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }