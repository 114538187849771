var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-RotateScreen" },
    [
      _c(
        "object-fit",
        {
          staticClass: "c-RotateScreen-bg",
          attrs: { width: _vm.bgImg.width, height: _vm.bgImg.height }
        },
        [
          _c("img", {
            staticClass: "js-object-fit c-RotateScreen-bg-img",
            attrs: { src: _vm.bgImg.src }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "c-RotateScreen-content" }, [
        _c(
          "svg",
          {
            attrs: {
              width: "184",
              height: "180",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
              _c("g", { attrs: { stroke: "#FFF" } }, [
                _c("path", {
                  attrs: {
                    d:
                      "M139.12 54.493C119.51 28.47 82.515 23.27 56.492 42.88c-23.39 17.624-29.957 49.291-16.81 74.416m5.198 8.21c19.61 26.023 56.603 31.223 82.626 11.612 23.443-17.665 29.987-49.438 16.718-74.59"
                  }
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d:
                      "M140.831 41.99l-1.742 12.396-12.396-1.742M57.158 127.06l-12.396-1.743-1.742 12.396"
                  }
                })
              ]),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M81.533 67h19.934c1.935 0 3.533 1.59 3.533 3.513v38.974c0 1.924-1.598 3.513-3.533 3.513H81.533c-1.935 0-3.533-1.59-3.533-3.513V70.513C78 68.589 79.598 67 81.533 67zm7.57 42.822c-.505 0-.841-.335-.841-.837 0-.501.336-.836.84-.836h4.88a.84.84 0 01.84.836.84.84 0 01-.84.837h-4.88zm-8.748-37.135v32.284h22.374V72.687H80.355z",
                  fill: "#FFF"
                }
              })
            ])
          ]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "c-RotateScreen-desc t-text--xs" }, [
          _vm._v(_vm._s(_vm.t("common.tuto_rotate")))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }