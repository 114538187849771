import Deferred from "utils/Deferred";
import ResourceGroup from "./ResourceGroup";
import { Resource } from "./Resource";
const _domParser = new DOMParser();
export async function loadText(url, resourceGroup, init) {
    const resource = getFetchResource(url, resourceGroup, init) || new StringResource(url, resourceGroup, init);
    return await resource.response();
}
export async function loadJson(url, resourceGroup, init) {
    const resource = getFetchResource(url, resourceGroup, init) || new JsonResource(url, resourceGroup, init);
    return await resource.response();
}
export async function loadBytes(url, resourceGroup, init) {
    // Arraybuffer can be detached wich breaks cache
    // for instance decode audio data detach the arraybuffer
    let resource = getFetchResource(url, resourceGroup, init);
    if (resource && resource.value.byteLength === 0) {
        resource.group.removeResource(resource);
    }
    resource = getFetchResource(url, resourceGroup, init) || new ArrayBufferResource(url, resourceGroup, init);
    return await resource.response();
}
export async function loadBlob(url, resourceGroup, init) {
    const resource = getFetchResource(url, resourceGroup, init) || new BlobResource(url, resourceGroup, init);
    return await resource.response();
}
export async function loadXml(url, resourceGroup, init) {
    const resource = getFetchResource(url, resourceGroup, init) || new XmlResource(url, resourceGroup, init);
    return await resource.response();
}
export async function loadImage(url, resourceGroup, init) {
    const resource = getFetchResource(url, resourceGroup, init) || new ImageResource(url, resourceGroup, init);
    return await resource.response();
}
function getFetchResource(url, resourceGroup, init) {
    if (!resourceGroup)
        resourceGroup = ResourceGroup.default;
    try {
        return resourceGroup.getResource(url.toString());
    }
    catch (_a) {
        return null;
    }
}
let AbortControllerFactory;
const _ACShim = {
    abort() { },
    signal: undefined
};
if (window.AbortController !== undefined) {
    AbortControllerFactory = () => new AbortController();
}
else {
    AbortControllerFactory = () => _ACShim;
}
export function CreateAbortController() {
    return AbortControllerFactory();
}
function validateResponse(response) {
    if (!response.ok) {
        throw new Error(`NetworkError: status : ${response.status} ${response.statusText}, url : ${response.url}`);
    }
}
/**
 * fetch equivalent which reject if status is not OK
 *
 * The Promise returned from fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500.
 * Instead, it will resolve normally (with ok status set to false), and it will only reject on network failure or
 * if anything prevented the request from completing.
 */
export async function fetchSafe(input, init) {
    const response = await fetch(input, init);
    validateResponse(response);
    return response;
}
class FetchResource extends Resource {
    constructor(url, resourceGroup, init = {}) {
        super(url.toString(), resourceGroup);
        this.url = url;
        this.init = init;
        init.signal = this._abortCtrl.signal;
    }
    load() {
        this.fetch();
    }
    unload() {
        this._abortCtrl.abort();
    }
    async fetch() {
        try {
            const response = await fetchSafe(this.url, this.init);
            const result = await this.parseResponse(response);
            this.resolve(result);
        }
        catch (e) {
            console.log(e);
            this.reject(e);
        }
        finally {
        }
    }
}
class StringResource extends FetchResource {
    parseResponse(response) {
        return response.text();
    }
}
class JsonResource extends FetchResource {
    parseResponse(response) {
        return response.json();
    }
}
class ArrayBufferResource extends FetchResource {
    parseResponse(response) {
        return response.arrayBuffer();
    }
}
class BlobResource extends FetchResource {
    parseResponse(response) {
        return response.blob();
    }
}
class XmlResource extends FetchResource {
    async parseResponse(response) {
        const text = await response.text();
        return _domParser.parseFromString(text, "application/xml");
    }
}
class ImageResource extends FetchResource {
    async parseResponse(response) {
        const blob = await response.blob();
        const src = URL.createObjectURL(blob);
        const img = new Image();
        const def = new Deferred();
        img.onload = def.resolve;
        img.onerror = def.reject;
        img.src = src;
        try {
            await def.promise;
        }
        finally {
            URL.revokeObjectURL(src);
        }
        await img.decode();
        return img;
    }
}
