import GLView from './GLview';
import Config from './Config';
import Quality from '@webgl/lib/Quality';
/**
  @opts :

    REQUIRED:
    'canvas'    {HTMLCanvasElement}   :  context free canvas element in which scene will be rendered
    'subtitles' {HTMLElement}         :  a dom element in which subtitles will be innerHTML-ed
    'config'    {string}              :  url to the xml config file
    'assetsUrl' {string}              :  3D assets base url without trailing slash
    'model'     {Model}               :  Model instance shared with the page

    OPTIONAL:
    'ilayer'    {HTMLElement}         :  dom elemnt on which touch/mouse event are listened, default to canvas element
    'quality'   {string}              :  enum 'auto', 'hi' or 'low'

**/
export default class GLApp {
    // scene: Scene
    // activeScene: IScene;
    constructor({ assetsUrl, canvas, ilayer = canvas, quality }) {
        Config.setBaseDir(assetsUrl);
        this.glview = new GLView(canvas);
        this.ilayer = ilayer;
        Quality.setMode(quality, this.glview.gl);
        console.log("new GLApp");
    }
    play() {
        this.glview.play();
    }
    async initScene(scene) {
        scene.ilayer = this.ilayer;
        scene.init(this.glview);
        await scene.load();
        return scene;
    }
    setActive(scene) {
        this.glview.scene = scene;
        return scene;
    }
    // async loadScene(scene: IScene): Promise<IScene> {
    //     scene.ilayer = this.ilayer;
    //     scene.init(this.glview);
    //     await scene.load();
    //     return scene;
    // }
    // async setActive(scene: IScene): Promise<IScene> {
    //     this.glview.scene = scene;
    //     await scene.enter();
    //     return scene;
    // }
    async releaseScene(scene) {
        // await scene.leave();
        await scene.dispose();
        this.glview.scene = null;
    }
}
if (module.hot) {
    // ResourceGroup.default = new ResourceGroup( 'main', null );
    // reset()
}
//////////
