import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from "./Routes";
import { getGlApp } from "mixins/GLAppProvider";
import datas from 'datas';
import { I18n } from "core/I18n";
Vue.use(VueRouter);
let base = '';
/////////////////////////
/////////////////////
//////////
////////////////////////////
/////////////////////
//////////
const router = new VueRouter({
    mode: 'history',
    base,
    routes
});
router.beforeResolve(async (to, from, next) => {
    if (from.matched.length > 0) {
        const componentModule = from.matched[0].components.default._module;
        const module = componentModule.methods.getModule();
        if (module.name !== 'Intro' && !module.data.loaded) {
            console.warn('[Router] Route to "' + to.fullPath + '" was prevented while "' + from.fullPath + '" was still loading');
            next(false);
            return;
        }
    }
    // Handles /en-us/ksdfjsdfds
    if (to.name === '404') {
        console.warn('[Router] Non Existing route "' + to.fullPath + '", redirecting to "/' + I18n.locale + '"');
        next(false);
        router.push({ name: 'intro', params: { lang: I18n.locale } });
        return;
    }
    if (datas.store.menuOpen) {
        datas.store.menuOpen = false;
    }
    if (I18n.locale !== to.params.lang) {
        try {
            await I18n.setLocale(to.params.lang);
        }
        catch (error) {
            // Handles non existant language
            console.warn('[Router] Non Existing locale "' + to.params.lang + '", redirecting to "' + I18n.locale + '"');
            next(false);
            router.push({ name: 'intro', params: { lang: I18n.locale } });
            return;
        }
    }
    document.title = I18n.t('meta.title');
    if (to.meta && to.meta.title) {
        document.title += ' | ' + I18n.t(to.meta.title);
    }
    if (to.name == from.name) {
        next();
        return;
    }
    // Instantiate target module
    // Preload target module
    if (to.matched.length > 0) {
        const componentModule = to.matched[0].components.default._module;
        componentModule.instantiateModule();
        componentModule.methods.getModule().init(getGlApp());
        // Preload mandatory asset (loading screen for instance)
        await componentModule.methods.getModule().preload();
    }
    // Leave animation current module
    // Release current module
    if (from.matched.length > 0) {
        const componentModule = from.matched[0].components.default._module;
        await componentModule.methods.getModule().leave();
        await componentModule.releaseModule();
    }
    next();
});
router.beforeEach((to, from, next) => {
    const hasQueryParams = (route) => !!Object.keys(route.query).length;
    if (!hasQueryParams(to) && hasQueryParams(from)) {
        next({ name: to.name, query: from.query });
    }
    else {
        next();
    }
});
export default router;
