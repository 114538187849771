//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import gsap from 'gsap';
export default {
  watch: {
    '$appdatas.store.soundMuted': 'onSoundMutedChange'
  },

  mounted() {
    this.bars = [{
      s: 0.2
    }, {
      s: 0.8
    }, {
      s: 0.7
    }, {
      s: 1
    }, {
      s: 5
    }];
    this.$nextTick(() => {
      const canvas = this.$el.querySelector("canvas");
      const ctx = canvas.getContext("2d");
      const w = 18;
      const h = 11;
      this.scaleCanvas(canvas, ctx, w, h);
      this.ctx = ctx;
      this.canvas = canvas;
      this.w = w;
      this.h = h;
      this.drawBars();
    });
    this.soundTimelines = [];
    const tl1 = gsap.timeline({
      repeat: -1,
      onUpdate: () => {
        this.drawBars();
      }
    });
    tl1.fromTo(this.bars[0], {
      s: 0.2
    }, {
      duration: 0.8,
      s: 1
    }, 0);
    tl1.to(this.bars[0], {
      duration: 0.8,
      s: 0.1
    }, 0.8);
    tl1.to(this.bars[0], {
      duration: 0.3,
      s: 0.2
    }, 1.6);
    tl1.timeScale(3);
    this.soundTimelines.push(tl1);
    const tl2 = gsap.timeline({
      repeat: -1
    });
    tl2.fromTo(this.bars[1], {
      s: 0.8
    }, {
      duration: 0.8,
      s: 1
    }, 0.5);
    tl2.to(this.bars[1], {
      duration: 0.4,
      s: 0.2
    }, 1.3);
    tl2.to(this.bars[1], {
      duration: 0.4,
      s: 0.8
    }, 1.7);
    tl2.timeScale(3);
    this.soundTimelines.push(tl2);
    const tl3 = gsap.timeline({
      repeat: -1
    });
    tl3.fromTo(this.bars[2], {
      s: 0.7
    }, {
      duration: 0.8,
      s: 1
    }, 0.8);
    tl3.to(this.bars[2], {
      duration: 0.4,
      s: 0.2
    }, 1.6);
    tl3.to(this.bars[2], {
      duration: 0.4,
      s: 0.7
    }, 2.0);
    tl3.timeScale(3);
    this.soundTimelines.push(tl3);
    const tl4 = gsap.timeline({
      repeat: -1
    });
    tl4.fromTo(this.bars[3], {
      s: 1
    }, {
      duration: 0.8,
      s: 0.2
    }, 0);
    tl4.to(this.bars[3], {
      duration: 0.4,
      s: 1
    }, 0.8);
    tl4.timeScale(3);
    this.soundTimelines.push(tl4);
    const tl5 = gsap.timeline({
      repeat: -1
    });
    tl5.fromTo(this.bars[4], {
      s: 0.5
    }, {
      duration: 0.8,
      s: 0.2
    }, 0.3);
    tl5.to(this.bars[4], {
      duration: 0.4,
      s: 1
    }, 1.1);
    tl5.to(this.bars[4], {
      duration: 0.4,
      s: 0.5
    }, 1.5);
    tl5.timeScale(3);
    this.soundTimelines.push(tl5);
  },

  methods: {
    scaleCanvas(canvas, context, width, height) {
      // assume the device pixel ratio is 1 if the browser doesn't specify it
      const devicePixelRatio = window.devicePixelRatio || 1; // determine the 'backing store ratio' of the canvas context

      const backingStoreRatio = context.webkitBackingStorePixelRatio || context.mozBackingStorePixelRatio || context.msBackingStorePixelRatio || context.oBackingStorePixelRatio || context.backingStorePixelRatio || 1; // determine the actual ratio we want to draw at

      const ratio = devicePixelRatio / backingStoreRatio;

      if (devicePixelRatio !== backingStoreRatio) {
        // set the 'real' canvas size to the higher width/height
        canvas.width = width * ratio;
        canvas.height = height * ratio; // ...then scale it back down with CSS

        canvas.style.width = width + 'px';
        canvas.style.height = height + 'px';
      } else {
        // this is a normal 1:1 device; just scale it simply
        canvas.width = width;
        canvas.height = height;
        canvas.style.width = '';
        canvas.style.height = '';
      } // scale the drawing context so everything will work at the higher ratio


      context.scale(ratio, ratio);
    },

    drawBars() {
      const canvas = this.canvas;
      const ctx = this.ctx;
      ctx.clearRect(0, 0, canvas.width, canvas.height); // ctx.fillStyle = this.$appdatas.store.menuOpen ? "#17233A" : "#ffffff"

      ctx.fillStyle = "#ffffff";
      this.bars.forEach((bar, i) => this.drawBar(i, bar));
    },

    drawBar(i, bar) {
      const canvas = this.canvas;
      const ctx = this.ctx;
      const w = 2;
      const h = this.h * bar.s;
      const x = i * 5;
      const y = this.h - h;
      ctx.fillRect(x, y, w, h);
    },

    onSoundMutedChange() {
      if (this.$appdatas.store.soundMuted) {
        this.soundTimelines.forEach((tl, i) => {
          tl.pause();

          const onUpdate = () => {
            if (i === 0) {
              this.drawBars();
            }
          };

          gsap.to(this.bars[i], {
            duration: 0.2,
            s: 0.2,
            onUpdate
          });
        });
      } else {
        this.soundTimelines.forEach(tl => {
          tl.play(0);
        });
      }
    }

  }
};