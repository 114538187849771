import { GAME_ID } from "datas";
import { I18n } from "core/I18n";
// MODULES
// =======
const Intro = () => import("modules/intro/Intro.vue");
const Hub = () => import("modules/hub/Hub.vue");
const Game1 = () => import("modules/game1/Game1.vue");
const Game2 = () => import("modules/game2/Game2.vue");
const Game3 = () => import("modules/game3/Game3.vue");
const Game4 = () => import("modules/game4/Game4.vue");
// SAMPLES
// =======
/////////////
const TriangleSample = () => import("modules/samples/hello-triangle/HelloTriangle.vue");
const ParticlesSample = () => import("modules/samples/particles/Particles.vue");
const GltfSample = () => import("modules/samples/gltf/GltfSample.vue");
const IBLEnvSample = () => import("modules/samples/iblenv-sample/IBLEnvSample.vue");
const SkinningSample = () => import("modules/samples/skinning/Skinning.vue");
const SceneGraph = () => import("modules/samples/scene-graph/SceneGraphSample.vue");
const PostProcess = () => import("modules/samples/postprocess/Postprocess.vue");
const TexturesSample = () => import("modules/samples/textures/TexturesSample.vue");
const AudioSample = () => import("modules/samples/audio-sample/AudioSample.vue");
const LoadingSample = () => import("modules/samples/loading-sample/LoadingSample.vue");
const Dummy = () => import("modules/samples/dummy/Dummy.vue");
//////////
export default [
    { path: "/game-1", redirect: { name: GAME_ID.GAME_1, params: { lang: I18n.locale } } },
    { path: "/game-2", redirect: { name: GAME_ID.GAME_2, params: { lang: I18n.locale } } },
    { path: "/game-3", redirect: { name: GAME_ID.GAME_3, params: { lang: I18n.locale } } },
    { path: "/game-4", redirect: { name: GAME_ID.GAME_4, params: { lang: I18n.locale } } },
    { path: "/hub", redirect: { name: 'hub', params: { lang: I18n.locale } } },
    { path: "/:lang", name: "intro", component: Intro },
    { path: "/:lang/hub", name: "hub", component: Hub },
    { path: "/:lang/game-1", name: GAME_ID.GAME_1, component: Game1, meta: { title: 'meta.og_title_game1' } },
    { path: "/:lang/game-2", name: GAME_ID.GAME_2, component: Game2, meta: { title: 'meta.og_title_game2' } },
    { path: "/:lang/game-3", name: GAME_ID.GAME_3, component: Game3, meta: { title: 'meta.og_title_game3' } },
    { path: "/:lang/game-4", name: GAME_ID.GAME_4, component: Game4, meta: { title: 'meta.og_title_game4' } },
    { path: '*', name: '404' },
    // SAMPLES
    // =======
    /////////////////
    { path: "*/samples/triangle", name: "samples-triangle", component: TriangleSample },
    { path: "*/samples/particles", name: "samples-particles", component: ParticlesSample },
    { path: "*/samples/gltf", name: "samples-gltf", component: GltfSample },
    { path: "*/samples/ibl-env", name: "samples-ibl-env", component: IBLEnvSample },
    { path: "*/samples/skinning", name: "samples-skinning", component: SkinningSample },
    { path: "*/samples/scene-graph", name: "samples-scene-graph", component: SceneGraph },
    { path: "*/samples/postprocess", name: "samples-postprocess", component: PostProcess },
    { path: "*/samples/textures", name: "samples-texturessample", component: TexturesSample },
    { path: "*/samples/audio", name: "samples-audio", component: AudioSample },
    { path: "*/samples/loading", name: "samples-loading", component: LoadingSample },
    { path: "*/samples/dummy", name: "samples-dummy", component: Dummy }
    //////////////
];
