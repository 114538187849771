let _glApp;
export function registerGLApp(app) {
    _glApp = app;
}
export function getGlApp() {
    return _glApp;
}
export default {
    methods: {
        getGlApp() {
            return _glApp;
        }
    }
};
