import 'dev';
import 'vendors';
import 'styles/index.styl';
import 'datas';
// Prevent circular dependency with Resrouce
import "core/resource/Net";
import Vue from 'vue';
Vue.config.productionTip = false;
import App from './App.vue';
import router from "core/router/Router";
import BrowserDetect from 'vendors/BrowserDetect';
import ResourceGroup from 'core/resource/ResourceGroup';
ResourceGroup.default.load();
Vue.prototype.$eventHub = new Vue();
import { I18n } from "core/I18n";
window.addEventListener("DOMContentLoaded", (event) => {
    // Check browser compatibility
    const browserSupportContainer = document.querySelector('.js-outdated');
    if (BrowserDetect.isIE) {
        browserSupportContainer.style.backgroundImage = "/assets/img/outdated.jpg";
        browserSupportContainer.classList.add('is-active');
        return;
    }
    else {
        document.body.removeChild(browserSupportContainer);
    }
    Vue.observable(I18n);
    console.log(window.user_lang);
    I18n.setLocale(window.user_lang)
        .then(() => {
        Vue.mixin({
            methods: {
                t(key, data) {
                    return I18n.t(key, data);
                },
                tAndReplaceYouthGenerating(key, data) {
                    return I18n.t(key, data).replace(/(Youth-Generating)/im, '<nobr>$1</nobr>');
                }
            },
            computed: {
                currentLocale() {
                    return I18n.locale;
                }
            }
        });
        new Vue({
            router,
            render: h => h(App),
        }).$mount('#app');
    });
});
