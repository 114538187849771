var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-App" },
    [
      _c("GLViewer", { ref: "viewer", attrs: { assetsUrl: "/assets/webgl" } }),
      _vm._v(" "),
      _vm.devMenu ? _c("VMenu") : _vm._e(),
      _vm._v(" "),
      _vm.$appdatas.store.debug && _vm.devMenu ? _c("SamplesMenu") : _vm._e(),
      _vm._v(" "),
      _c("Layout", { ref: "layout" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }