// WEBGL ASSETS CONFIG
// ===================
import { TextureCodecs } from './assets/TextureCodec';
import { TextureCodecBasis } from './assets/TextureCodecBasis';
import TextureCodecStd from './assets/TextureCodecStd';
import TextureCodecDxt from './assets/TextureCodecDxt';
import TextureCodecEtc from './assets/TextureCodecEtc';
import TextureCodecPvr from './assets/TextureCodecPvr';
TextureCodecs.registerCodec(new TextureCodecBasis());
TextureCodecs.registerCodec(new TextureCodecStd());
TextureCodecs.registerCodec(new TextureCodecDxt());
TextureCodecs.registerCodec(new TextureCodecEtc());
TextureCodecs.registerCodec(new TextureCodecPvr());
import Gltf from './lib/nanogl-gltf/lib';
import KHR_texture_transform from './lib/nanogl-gltf/lib/extensions/KHR_texture_transform';
import KHR_draco_mesh_compression from './lib/nanogl-gltf/lib/extensions/KHR_draco_mesh_compression';
import KHR_materials_pbrSpecularGlossiness from './lib/nanogl-gltf/lib/extensions/KHR_materials_pbrSpecularGlossiness';
import KHR_lights_punctual from './lib/nanogl-gltf/lib/extensions/KHR_lights_punctual';
import KHR_materials_unlit from './lib/nanogl-gltf/lib/extensions/KHR_materials_unlit';
import EXT_texture_webp from './lib/nanogl-gltf/lib/extensions/EXT_texture_webp';
import MMP_compressed_texture from './lib/nanogl-gltf/lib/extensions/MMP_compressed_texture';
Gltf.addExtension(new KHR_texture_transform());
Gltf.addExtension(new KHR_draco_mesh_compression());
Gltf.addExtension(new KHR_materials_pbrSpecularGlossiness());
Gltf.addExtension(new KHR_lights_punctual());
Gltf.addExtension(new KHR_materials_unlit());
Gltf.addExtension(new EXT_texture_webp());
Gltf.addExtension(new MMP_compressed_texture());
const DEFAULT_DIR = '/assets/webgl';
let _assetsDir = DEFAULT_DIR;
const Config = {
    isWebgl2: false,
    /**
     * @param {string} path
     * @return {string} prefixed path
     */
    asset_url(path) {
        return _assetsDir + '/' + path;
    },
    setBaseDir(dir = DEFAULT_DIR) {
        _assetsDir = dir;
    },
    api(service) {
        return './apis/' + service;
    }
};
export default Config;
