var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass: "c-LangPanel-overlay",
      class: { "is-panel-active": _vm.$appdatas.store.langPanelOpen === true },
      on: {
        click: function($event) {
          _vm.$appdatas.store.langPanelOpen = false
        }
      }
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "c-LangPanel",
        class: { "is-panel-active": _vm.$appdatas.store.langPanelOpen === true }
      },
      [
        _c("div", { staticClass: "c-LangPanel-header" }, [
          _c("p", { staticClass: "c-LangPanel-header-title" }, [
            _vm._v(_vm._s(_vm.t("common.select_loca")))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { ref: "scrollableElement", staticClass: "c-LangPanel-inner" },
          [
            _c("div", { staticClass: "c-LangPanel-content" }, [
              _c(
                "ul",
                { staticClass: "t-list" },
                _vm._l(_vm.locales, function(item, i) {
                  return _c("li", { key: i }, [
                    _c(
                      "button",
                      {
                        staticClass: "c-LangPanel-item t-btn",
                        class: {
                          "is-active":
                            _vm.getCountry(item.locale) === _vm.currentCountry
                        },
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.onLocaleChange(item.languages[0].locale)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "c-LangPanel-item-label" }, [
                          _vm._v(_vm._s(item.label))
                        ])
                      ]
                    )
                  ])
                }),
                0
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }