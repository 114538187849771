import Gltf from "../..";
import { mat3 } from "gl-matrix";
import TexCoord from "nanogl-pbr/TexCoord";
import { Sampler } from "nanogl-pbr/Input";
const M3 = mat3.create();
const EXT_ID = 'KHR_texture_transform';
function isTextureInfo(element) {
    if (element.createSampler === undefined) {
        throw new Error("KHR_texture_transform used on element which doesn't implement ITextureInfo");
    }
    // const t = element.gltftype
    // return (
    //   t === GltfTypes.TEXTURE_INFO ||  
    //   t === GltfTypes.NORMAL_TEXTURE_INFO ||  
    //   t === GltfTypes.OCCLUSION_TEXTURE_INFO 
    // );
}
class TransformData {
    constructor(extData) {
        var _a, _b, _c;
        this.offset = (_a = extData.offset) !== null && _a !== void 0 ? _a : [0, 0];
        this.scale = (_b = extData.scale) !== null && _b !== void 0 ? _b : [1, 1];
        this.rotation = (_c = extData.rotation) !== null && _c !== void 0 ? _c : 0;
        this.texCoord = extData.texCoord;
    }
    getMatrix(m3) {
        const cos = Math.cos(this.rotation);
        const sin = Math.sin(this.rotation);
        m3[0] = this.scale[0] * cos;
        m3[1] = this.scale[0] * sin;
        m3[3] = this.scale[1] * -sin;
        m3[4] = this.scale[1] * cos;
        m3[6] = this.offset[0];
        m3[7] = this.offset[1];
    }
}
let _samplerUID = 0;
class Instance {
    constructor(gltfLoader) {
        this.name = EXT_ID;
        this.priority = -1;
        this.loader = gltfLoader;
    }
    wrapElement(element, extData) {
        element.createSampler = () => {
            var _a;
            const e = element;
            if (e._sampler === null) {
                extData.getMatrix(M3);
                const index = (_a = extData.texCoord) !== null && _a !== void 0 ? _a : element.texCoord;
                const attrib = Gltf.getSemantics().getAttributeName(`TEXCOORD_${index}`);
                const tc = TexCoord.createTransformed(attrib, M3);
                const sampler = new Sampler(`tex${name !== null && name !== void 0 ? name : ''}tt_${_samplerUID++}`, tc);
                e._sampler = sampler;
                element.texture.glTexturePromise.then((t) => sampler.set(t));
            }
            return e._sampler;
        };
    }
    acceptElement(data, element) {
        if (data.extensions && data.extensions[EXT_ID]) {
            isTextureInfo(element);
            const transformData = new TransformData(data.extensions[EXT_ID]);
            this.wrapElement(element, transformData);
        }
        return Promise.resolve(element);
    }
    loadElement(data) {
        return null;
    }
}
export default class KHR_texture_transform {
    constructor() {
        this.name = EXT_ID;
    }
    createInstance(gltfLoader) {
        return new Instance(gltfLoader);
    }
}
