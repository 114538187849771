import Assert from "utils/Assert";
export default class ResourceGroup {
    constructor(name = 'default', parent = ResourceGroup.default) {
        //    _____                         _                 
        //   / ____|                       | |                
        //  | |  __ _ __ ___  _   _ _ __   | |_ _ __ ___  ___ 
        //  | | |_ | '__/ _ \| | | | '_ \  | __| '__/ _ \/ _ \
        //  | |__| | | | (_) | |_| | |_) | | |_| | |  __/  __/
        //   \_____|_|  \___/ \__,_| .__/   \__|_|  \___|\___|
        //                         | |                        
        //                         |_|                        
        this._parent = null;
        //   _                 _       _        _       
        //  | |               | |     | |      | |      
        //  | | ___   __ _  __| |  ___| |_ __ _| |_ ___ 
        //  | |/ _ \ / _` |/ _` | / __| __/ _` | __/ _ \
        //  | | (_) | (_| | (_| | \__ \ || (_| | ||  __/
        //  |_|\___/ \__,_|\__,_| |___/\__\__,_|\__\___|
        /**
         * is this group as been marked to load with load() / unload()
         */
        this._selfShouldLoad = false;
        /**
         * Actual loading state based on _selfShouldLoad and ancestors state
         */
        this._isLoading = false;
        this.name = name;
        this._subGroups = [];
        this._resourcesMap = new Map();
        this._resourcesList = [];
        // this.parent = parent;
        if (parent)
            parent.addSubGroup(this);
    }
    set parent(p) {
        if (this._parent)
            this._parent.removeSubGroup(this);
        this._parent = p;
        this._checkLoadState();
    }
    get parent() {
        return this._parent;
    }
    addSubGroup(group) {
        if (group.parent) {
            group.parent.removeSubGroup(this);
        }
        group.parent = this;
        this._subGroups.push(group);
    }
    removeSubGroup(group) {
        const index = this._subGroups.indexOf(group);
        group.parent = null;
        if (index > -1) {
            this._subGroups.splice(index, 1);
        }
    }
    _shouldLoad() {
        if (this.parent !== null)
            return this._selfShouldLoad || this.parent._shouldLoad();
        return this._selfShouldLoad;
    }
    _checkLoadState() {
        const shouldLoad = this._shouldLoad();
        if (this._isLoading !== shouldLoad) {
            this._isLoading = shouldLoad;
            if (this._isLoading)
                this._loadAllResources();
            else
                this._unloadAllResources();
        }
        for (const childs of this._subGroups) {
            childs._checkLoadState();
        }
    }
    /**
     * Mark this group for loading, ancestor should also be mark as loading to trigger actual loading
     */
    load() {
        this._selfShouldLoad = true;
        this._checkLoadState();
        return this;
    }
    /**
     * Mark this group for unloading
     */
    unload() {
        this._selfShouldLoad = false;
        this._checkLoadState();
        return this;
    }
    /**
     * Mark this group and all children for unloading
    */
    unloadAll() {
        this._selfShouldLoad = false;
        for (const childs of this._subGroups) {
            childs.unloadAll();
        }
        this._checkLoadState();
        return this;
    }
    _loadAllResources() {
        for (const resource of this._resourcesList)
            resource._load();
    }
    _unloadAllResources() {
        for (let i = this._resourcesList.length - 1; i >= 0; i--) {
            const resource = this._resourcesList[i];
            this.removeResource(resource);
        }
    }
    getLoadables() {
        return this._resourcesList.map(r => r.response());
    }
    collectAllLoadables(all) {
        this._resourcesList.forEach(r => all.push(r.response()));
        for (const childs of this._subGroups) {
            childs.collectAllLoadables(all);
        }
    }
    getAllLoadables() {
        const res = [];
        this.collectAllLoadables(res);
        return res;
    }
    whenAllLoadables() {
        return Promise.all(this.getAllLoadables());
    }
    //   _ __ ___  ___  ___  _   _ _ __ ___ ___  ___ 
    //  | '__/ _ \/ __|/ _ \| | | | '__/ __/ _ \/ __|
    //  | | |  __/\__ \ (_) | |_| | | | (_|  __/\__ \
    //  |_|  \___||___/\___/ \__,_|_|  \___\___||___/
    addResource(resource) {
        if (this._resourcesMap.has(resource.name)) {
            throw new Error(`ResourceGroup::addResource() resource with name ${resource.name} already exist`);
        }
        this._resourcesList.push(resource);
        this._resourcesMap.set(resource.name, resource);
        // TODO: proper resource managment
        Promise.resolve().then(() => {
            if (this._isLoading)
                resource._load();
        });
    }
    removeResourceByName(name) {
        this.removeResource(this.getResource(name));
    }
    removeResource(resource) {
        if (this._resourcesMap.delete(resource.name)) {
            const i = this._resourcesList.indexOf(resource);
            Assert.isTrue(i > -1);
            this._resourcesList[i]._unload();
            this._resourcesList.splice(i, 1);
        }
    }
    getResource(name) {
        const res = this._resourcesMap.get(name);
        if (res === undefined) {
            throw new Error(`ResourceGroup::getResource() resource with name ${name} doesn't exist`);
        }
        return res;
    }
    getResources(pattern, returnValues = false) {
        const res = [];
        for (let key of this._resourcesMap.keys()) {
            if (pattern.test(key)) {
                res.push(returnValues ? this.get(key) : this.getResource(key));
            }
        }
        return res;
    }
    get(name) {
        return this.getResource(name).value;
    }
    hasResource(name) {
        return this._resourcesMap.get(name) !== undefined;
    }
}
ResourceGroup.default = new ResourceGroup('main', null);
