var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "c-SoundButton t-btn",
      class: {
        "is-muted": _vm.$appdatas.store.soundMuted,
        "is-dark": _vm.$appdatas.store.menuOpen
      },
      attrs: { type: "button", "aria-label": _vm.t("common.active_sound") },
      on: {
        click: function($event) {
          _vm.$appdatas.store.soundMuted = !_vm.$appdatas.store.soundMuted
        }
      }
    },
    [_c("canvas")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }