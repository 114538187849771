import ResourceGroup from "./ResourceGroup";
import Deferred from "utils/Deferred";
import { CreateAbortController } from "./Net";
export class Resource {
    constructor(name, group = ResourceGroup.default) {
        this._value = null;
        this._isLoading = false;
        this.name = name;
        this._deferred = new Deferred();
        this._abortCtrl = CreateAbortController();
        this.group = group;
        group.addResource(this);
    }
    get value() {
        if (this._value === null)
            throw new Error(`Resource ${this.name} not loaded`);
        return this._value;
    }
    response() {
        return this._deferred.promise;
    }
    /**
     * Called by resource group when group start laoding its resources
     */
    _load() {
        if (this._isLoading)
            return;
        this._isLoading = true;
        this.load();
    }
    /**
     * Called by resource group when group stop loading it's resources
     */
    _unload() {
        if (!this._isLoading)
            return;
        this._isLoading = false;
        this._abortCtrl.abort();
        this.unload();
        this._value = null;
    }
    resolve(value) {
        this._value = value;
        this._deferred.resolve(value);
    }
    reject(reason) {
        this._deferred.reject(reason);
        this._value = null;
    }
}
export class LambdaResource extends Resource {
    constructor(loadFunc, group) {
        const id = (LambdaResource._UID++).toString();
        super(id, group);
        this._loadFunc = loadFunc;
    }
    static fromLoadable(loadable, group) {
        return new LambdaResource(() => Promise.all(loadable.getLoadables()), group);
    }
    load() {
        this._loadFunc().then((v) => this.resolve(v), (e) => this.reject(e));
    }
    unload() {
    }
}
LambdaResource._UID = 0;
