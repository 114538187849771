import { Resource } from "./Resource";
export class AudioResource extends Resource {
    constructor(name, audioPath, group) {
        super(name, group);
        this.audioPath = audioPath;
    }
    async load() {
        const audioSource = this.group.audioModule.createAudioSource(this.audioPath, {});
        try {
            await audioSource.load();
            this.resolve(audioSource);
            return audioSource;
        }
        catch (e) {
            console.log(e);
            audioSource.dispose();
            this.reject(e);
            return null;
        }
        finally {
        }
    }
    unload() {
        this._abortCtrl.abort();
        this.value.dispose();
    }
}
