import { Resource } from "./Resource";
export class ImageResource extends Resource {
    constructor(name, url, group) {
        super(name, group);
        this.url = url;
    }
    load() {
        let img = new Image();
        img.onload = () => {
            this.resolve(img);
        };
        img.src = this.url;
    }
    unload() {
        this._abortCtrl.abort();
    }
}
