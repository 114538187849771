export class TextureCodecs {
    static registerCodec(codec) {
        if (this._codecs[codec.name] !== undefined) {
            console.warn(`TextureCodec.registerCodec() Codec ${codec.name} already exist`);
            return;
        }
        this._codecs[codec.name] = codec;
    }
    static getCodecForRequest(request, extensions) {
        const sources = request.sources;
        for (const source of sources) {
            const codec = this._codecs[source.codec];
            if (codec !== undefined && codec.isSupported(extensions)) {
                return [codec, source];
            }
        }
        return null;
    }
}
TextureCodecs._codecs = {};
