var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-MenuPanel",
      class: { "is-active": _vm.isOpen, "is-forced": _vm.isForced }
    },
    [
      _c("div", { staticClass: "c-MenuPanel-inner" }, [
        _c("div", { staticClass: "c-MenuPanel-header" }),
        _vm._v(" "),
        _c("div", { staticClass: "c-MenuPanel-content" }, [
          _c(
            "div",
            { staticClass: "c-MenuPanel-content-inner" },
            [_vm._t("default")],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }