import Vue from 'vue';
import { I18n } from "core/I18n";
export var GAME_ID;
(function (GAME_ID) {
    GAME_ID["GAME_1"] = "GAME_1";
    GAME_ID["GAME_2"] = "GAME_2";
    GAME_ID["GAME_3"] = "GAME_3";
    GAME_ID["GAME_4"] = "GAME_4";
})(GAME_ID || (GAME_ID = {}));
export var GAME_SHARE_FILE_TITLE;
(function (GAME_SHARE_FILE_TITLE) {
    GAME_SHARE_FILE_TITLE["GAME_1"] = "Repair-Racer_Score";
    GAME_SHARE_FILE_TITLE["GAME_2"] = "Smooth-satisfaction_Score";
    GAME_SHARE_FILE_TITLE["GAME_3"] = "Serum-Quest_Score";
    GAME_SHARE_FILE_TITLE["GAME_4"] = "Beauty-Bounce_Score";
})(GAME_SHARE_FILE_TITLE || (GAME_SHARE_FILE_TITLE = {}));
export class Datas {
    constructor() {
        this.noGUI = false;
        this.noMusic = false;
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("no-gui") === "1")
            this.noGUI = true;
        if (urlParams.get("no-music") === "1")
            this.noMusic = true;
        // vpvParam
        this.store = Vue.observable({
            debug: false,
            menuOpen: false,
            soundMuted: false,
            langPanelOpen: false,
            skipInstruction: false,
            sharethisLoaded: true,
            recaptchaLoaded: true,
            screen: {
                width: window.innerWidth,
                height: window.innerHeight,
            },
            completeGames: [],
            pageTracking: '/landing',
            hubIndex: 0,
            hubTapPos: {
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
            },
            bottleHovered: false,
            bottleEntered: false,
            showHubPreview: false,
            utm_source: null,
            utm_campaign: null,
            isRetail: false
        });
        // TODO MOVE TO OTHER
        /////////////////////
        this.store.debug = true;
        //////////////////
        // global audio UI (play hover / click sound)
        this.audioLayout = null;
        // Events
        window.addEventListener('resize', this.onResize.bind(this));
        window.addEventListener('orientationchange', this.onResize.bind(this));
    }
    onResize() {
        this.store.screen.width = window.innerWidth;
        this.store.screen.height = window.innerHeight;
    }
    sendEvent(eventCategory = '', eventAction = '', eventLabel = '', eventValue = '') {
        if (!window.dataLayer) {
            console.warn('dataLayer not initialize');
            return;
        }
        const options = {
            'event': 'eventPush',
            'eventCategory': eventCategory,
            'eventAction': eventAction,
            'eventLabel': eventLabel,
            'eventValue': eventValue,
            'localeCode': I18n.locale,
            'countryCode': I18n.country,
            'retail': this.store.isRetail
        };
        console.log('[GTM] dataLayer.push', options);
        window.dataLayer.push(options);
    }
}
const datas = new Datas();
function provideAppData() {
    this.$appdatas = datas;
}
Vue.mixin({ beforeCreate: provideAppData });
export default datas;
