import { I18n } from "core/I18n";
export default {
  methods: {
    requestNav(routeName) {
      this.$router.push({
        name: routeName,
        params: {
          lang: this.currentLocale
        }
      });
    }

  }
};