import Image from "../../elements/Image";
export default class CompressedImage extends Image {
    async parseCompressed(gltfLoader, data, texLoader) {
        const codec = texLoader.getCodec();
        if (data.uri) {
            this.uri = data.uri;
            this.resolvedUri = gltfLoader.resolveUri(this.uri);
            this.resolvedUri = codec.transformPath(this.resolvedUri);
        }
        let rawData = await gltfLoader.gltfIO.loadBinaryResource(this.resolvedUri, gltfLoader.abortSignal);
        this.texDataSource = codec.parser.parse(rawData);
    }
    async setupTexture(texture, wrapS, wrapT, minFilter, magFilter) {
        const gl = texture.gl;
        texture.bind();
        var mips = this.texDataSource.surfaces[0];
        var fmt = this.texDataSource.format;
        var w = this.texDataSource.width;
        var h = this.texDataSource.height;
        for (var i = 0; i < mips.length; i++) {
            var m = mips[i];
            gl.compressedTexImage2D(gl.TEXTURE_2D, i, fmt, w, h, 0, m);
            w = w >> 1;
            h = h >> 1;
        }
        ;
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, magFilter);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, minFilter);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, wrapS);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, wrapT);
    }
}
