var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-Share" }, [
    _c(
      "ul",
      {
        staticClass: "c-Share-list t-list",
        attrs: { id: "Share_" + _vm._uid }
      },
      _vm._l(_vm.shareList, function(item, index) {
        return _c("li", { key: index, staticClass: "c-Share-item" }, [
          _c(
            "div",
            {
              staticClass: "t-link",
              attrs: {
                "data-network": item.slug,
                "data-url": _vm.url,
                "data-short-url": _vm.shortUrl,
                "data-title": _vm.title,
                "data-image": _vm.imageSrc,
                "data-description": _vm.description,
                "data-username": _vm.username,
                "data-message": _vm.message
              }
            },
            [
              _c(
                "span",
                { staticClass: "c-Share-item-icon" },
                [_c("ui-icon", { attrs: { type: item.slug } })],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "c-Share-item-title t-link--primary" },
                [_vm._v(_vm._s(item.name))]
              )
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }