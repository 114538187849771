import GltfTypes from "../../types/GltfTypes";
import CompressedImage from "./CompressedImage";
import TextureLoader from "./TextureLoader";
/**
 * Load ktx textures into image
 * Resolve the compressed supported type
 */
const EXT_ID = 'MMP_compressed_texture';
class Instance {
    constructor(gltfLoader) {
        this.name = EXT_ID;
        this.priority = 1;
        this.loader = gltfLoader;
        this.textureLoader = new TextureLoader();
    }
    async acceptElement(data, element) {
        return element;
    }
    loadElement(data) {
        // TODO :
        // Move to extenstion not extras
        let hasCompressed = data.gltftype === GltfTypes.IMAGE && data.extras && data.extras.MMP_compressed_texture;
        hasCompressed = hasCompressed && this.textureLoader.hasCodec();
        if (!hasCompressed)
            return null;
        const comp = new CompressedImage();
        return comp.parseCompressed(this.loader, data, this.textureLoader).then(() => comp);
    }
}
export default class MMP_compressed_texture {
    constructor() {
        this.name = EXT_ID;
    }
    createInstance(gltfLoader) {
        return new Instance(gltfLoader);
    }
}
