import { I18n } from "core/I18n";
export default {
  methods: {
    onInputChange() {
      if (this.$refs.samplesSelect.value != "") {
        this.$router.push({
          name: this.$refs.samplesSelect.value,
          params: {
            lang: this.currentLocale
          }
        });
      }
    }

  }
};