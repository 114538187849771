//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    image: {
      type: HTMLImageElement | Object
    },
    alt: {
      type: Boolean,
      default: false
    },
    gameId: {
      type: String,
      default: ''
    }
  },
  methods: {
    onBuy() {
      this.$appdatas.sendEvent('click', 'purchase', this.gameId);
    },

    onRetail() {// TODO: Send retail event
      //this.$appdatas.sendEvent('click', 'purchase', this.gameId);
    }

  }
};