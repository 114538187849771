import { AudioResource } from "core/resource/AudioResource";
export default class Audio {
    constructor(resources) {
        this.resources = resources;
        this.group = resources;
        this.setupAudioGroup();
    }
    setupAudioGroup() {
        new AudioResource("HOVER", "main/HOVER", this.group);
        new AudioResource("GENERAL_CLICK", "main/GENERAL_CLICK", this.group);
        new AudioResource("WHITE_BUTTON_CLICK", "main/WHITE_BUTTON_CLICK", this.group);
        new AudioResource("GAMESTART_PLAY_BUTTON", "main/GAMESTART_PLAY_BUTTON", this.group);
    }
    play(id, vol = 1) {
        const s = this.group.get(id).play(vol, false);
        this.currentSound = s;
    }
    dispose() {
        var _a;
        (_a = this.currentSound) === null || _a === void 0 ? void 0 : _a.dispose();
        this.currentSound = null;
    }
}
