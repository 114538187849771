//
//
//
//
import { iconTypes } from './iconTypes';
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'light' // Light / Dark

    }
  },
  data: () => {
    return {
      icon: ""
    };
  },
  computed: {
    typeClass() {
      return `ui-icon--${this.type}`;
    },

    colorClass() {
      return `ui-icon--${this.color}`;
    }

  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.icon = this.getTypeFile();
    },

    getTypeFile() {
      const fileItem = iconTypes.filter(item => item.type === this.type)[0];
      const file = fileItem.file;

      if (file) {
        return file;
      } else {
        console.log(`No svg found for type: ${this.type}`);
      }

      return "";
    }

  },
  watch: {
    typeClass: function (value) {
      this.init();
    }
  }
};