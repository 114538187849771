function _Throw(msg) {
    throw new Error(msg);
}
var Assert;
(function (Assert) {
    function isTrue(condition, msg) {
        !condition && _Throw(msg);
    }
    Assert.isTrue = isTrue;
    function isDefined(o, msg) {
        o !== null && o !== void 0 ? o : _Throw(msg);
    }
    Assert.isDefined = isDefined;
    function isNotNull(o, msg) {
        (o === null) && _Throw(msg);
    }
    Assert.isNotNull = isNotNull;
})(Assert || (Assert = {}));
export default Assert;
