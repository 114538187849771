var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-Menu",
      class: {
        "is-hidden": _vm.isHidden,
        "is-active": _vm.navOpen,
        "is-panel-active": _vm.isPanelOpen
      }
    },
    [
      _c("div", {
        staticClass: "c-Menu-overlay",
        on: {
          click: function($event) {
            _vm.$appdatas.store.menuOpen = false
          }
        }
      }),
      _vm._v(" "),
      _c("canvas", { ref: "canvas", staticClass: "c-Menu-canvas" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-Menu-inner" },
        [
          _c(
            "div",
            { staticClass: "c-Menu-header" },
            [
              _c(
                "router-link",
                {
                  staticClass: "c-Menu-logo t-link",
                  attrs: {
                    to: { name: "intro", params: { lang: _vm.currentLocale } }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "158",
                        height: "20",
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 158 20"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "#17233A",
                          d:
                            "M36.2 3.1v.7h-5.3v15.5H29V3.8h-5.3v-.7h12.5zM48.1.2v.6h-9.4V.2h9.4zM48.1 3.1v.7h-7.5v6.4h6.9v.6h-6.9v7.7h7.6v.7h-9.5V3.1h9.4zM61.1 3.1v.7h-7.5v6.4h6.9v.6h-6.9v7.7h7.6v.7h-9.5V3.1h9.4zM9.4 3.1v.7H1.9v6.4h6.9v.6H1.9v7.7h7.6v.7H0V3.1h9.4zM142 3.1v.7h-7.5v6.4h6.9v.6h-6.9v7.7h7.6v.7h-9.5V3.1h9.4zM73.1 3.1v15.5h7.5v.8h-9.4V3.1h1.9zM21.3 15.3c0-2.2-2.1-2.9-4.2-3.6-2.5-.8-5.1-1.7-5-4.7 0-2.8 2.8-4.3 5.4-4.3 1.5 0 3 .4 4.4 1.1v1.1c-1.2-1-2.7-1.5-4.3-1.6-2.2 0-4 1.3-4 3.1 0 2.2 2.1 2.9 4.3 3.6 2.5.8 5 1.7 5 4.6 0 3.4-3.3 5.1-6.4 5.1-1.6 0-3.2-.4-4.6-1.1v-1.1c.9.6 2.5 1.5 4.6 1.6 3.1 0 4.8-1.9 4.8-3.8zM90.2 3l2.7 6.3s3.7 8.6 4.3 10h-2.1c-.1-.2-2.6-6.3-2.6-6.3h-6.9s-2.5 6.1-2.5 6.3H82L88.7 3h1.5zm-1.3 1.8c-1.1 2.9-2.6 6.6-2.9 7.3l-.1.2h6.4l-.9-2.2L89 4.4l-.1.4zM111.7 3.1v11c0 3.5-2.4 5.5-6.4 5.5-4.2 0-6.5-1.9-6.5-5.7V3.1h1.8v11.3c0 2.9 1.8 4.7 4.9 4.7 3.2 0 5.2-1.9 5.2-5v-11h1zM151.4 3.1c2 0 3.6.5 4.5 1.4.7.7 1.1 1.7 1.1 2.7 0 3.2-3.9 4-4.1 4h-.2l5.4 8.1h-2l-5.1-7.9h-3.5v7.9h-1.9V3.1h5.8zm-.2.6h-3.8v7.1h2.7c1.8 0 3.2-.4 4.1-1.3.6-.6 1-1.5.9-2.4.1-.9-.3-1.8-.9-2.5-.6-.6-1.6-.9-3-.9zM121.5 3.1c2.5 0 4.6.6 6 2s2.1 3.4 2.1 5.9c0 2.6-.7 4.6-2.2 6-1.6 1.5-3.9 2.3-6.8 2.3h-5.4V3.1h6.3zm-.3.6h-4v15h3.1c2.5 0 4.4-.6 5.6-1.8 1.3-1.2 1.9-3.1 1.9-5.6 0-2.7-.6-4.6-2-5.9-1.2-1.1-2.7-1.7-4.6-1.7z"
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "c-Menu-back t-btn t-link--bd",
                  on: { click: _vm.closeActivePanel }
                },
                [_vm._v(_vm._s(_vm.t("common.cta_back")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "c-Menu-content" }, [
            _c(
              "div",
              { staticClass: "c-Menu-content-inner" },
              [
                _c(
                  "ul",
                  { staticClass: "c-Menu-links t-list" },
                  [
                    _c(
                      "li",
                      { staticClass: "c-Menu-links-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "c-Menu-links-item-link t-link",
                            attrs: {
                              to: {
                                name: "intro",
                                params: { lang: _vm.currentLocale }
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "c-Menu-links-item-img",
                                class: {
                                  "is-link-active": _vm.$route.name === "intro"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.introThumbnail.src,
                                    width: _vm.introThumbnail.width,
                                    height: _vm.introThumbnail.height
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "c-Menu-links-item-desc" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "c-Menu-links-item-desc-name t-text--sm"
                                  },
                                  [_vm._v(_vm._s(_vm.t("common.menu_link1")))]
                                ),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass:
                                    "c-Menu-links-item-desc-title t-text--lg",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.t("common.menu_link1_title")
                                    )
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "c-Menu-links-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "c-Menu-links-item-link t-link",
                            attrs: {
                              to: {
                                name: "hub",
                                params: { lang: _vm.currentLocale }
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "c-Menu-links-item-img",
                                class: {
                                  "is-link-active": _vm.$route.name === "hub"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.hubThumbnail.src,
                                    width: _vm.hubThumbnail.width,
                                    height: _vm.hubThumbnail.height
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "c-Menu-links-item-desc" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "c-Menu-links-item-desc-name t-text--sm"
                                  },
                                  [_vm._v(_vm._s(_vm.t("common.menu_link2")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "c-Menu-links-item-desc-title t-text--lg t-text--transform--initial"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.t("common.menu_link2_title"))
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.games, function(game, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "c-Menu-links-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "c-Menu-links-item-link t-link",
                              attrs: {
                                to: {
                                  name: game,
                                  params: { lang: _vm.currentLocale }
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "c-Menu-links-item-img",
                                  class: {
                                    "is-link-active": _vm.$route.name === game
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.gameThumbnails[index].src,
                                      alt: "Game " + index + " thumbnail",
                                      width: _vm.gameThumbnails[index].width,
                                      height: _vm.gameThumbnails[index].height
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "c-Menu-links-item-desc" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "c-Menu-links-item-desc-name t-text--sm"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.t(
                                            "common.menu_link" + (index + 3)
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "c-Menu-links-item-desc-title t-text--lg"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.t(
                                            "gaming." +
                                              _vm.formatGameString(game) +
                                              "_name"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("push-product", {
                  staticClass: "c-Menu-content-product",
                  attrs: { image: _vm.productImg }
                }),
                _vm._v(" "),
                _c("ul", { staticClass: "c-Menu-list t-list" }, [
                  _c("li", { staticClass: "c-Menu-list-item" }, [
                    _c(
                      "button",
                      {
                        staticClass: "t-btn t-link--nd",
                        on: { click: _vm.openSharePanel }
                      },
                      [_vm._v(_vm._s(_vm.t("common.cta_share_site")))]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.t("common.cta_legals_link")
                    ? _c("li", { staticClass: "c-Menu-list-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "t-btn t-link--nd",
                            attrs: {
                              href: _vm.t("common.cta_legals_link"),
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(_vm.t("common.cta_legals")))]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.t("common.cta_cookies")
                    ? _c("li", { staticClass: "c-Menu-list-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "ot-sdk-show-settings t-btn t-link--nd"
                          },
                          [_vm._v(_vm._s(_vm.t("common.cta_cookies")))]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.t("common.cta_terms_link")
                    ? _c("li", { staticClass: "c-Menu-list-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "t-btn t-link--nd",
                            attrs: {
                              href: _vm.t("common.cta_terms_link"),
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(_vm.t("common.cta_terms")))]
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.languages.length > 1
                  ? _c(
                      "ul",
                      { staticClass: "c-Menu-lang t-list" },
                      [
                        _vm._l(_vm.languages, function(item, i) {
                          return [
                            _c("li", { staticClass: "c-Menu-lang-item" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "c-Menu-lang-item-btn t-btn t-text--sm",
                                  class: {
                                    "is-active":
                                      item.locale === _vm.currentLocale
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onLocaleChange(item.locale)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.languages.length !== i + 1
                              ? _c(
                                  "li",
                                  { staticClass: "c-Menu-lang-sep t-text" },
                                  [_vm._v("-")]
                                )
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("lang-selector", {
            staticClass: "c-Menu-countries",
            attrs: { dark: true }
          }),
          _vm._v(" "),
          _c(
            "menu-panel",
            { ref: "panelShare" },
            [
              _c("h2", { staticClass: "c-MenuPanel-heading t-h5" }, [
                _vm._v(_vm._s(_vm.t("common.cta_share_site")))
              ]),
              _vm._v(" "),
              _c("share", {
                attrs: {
                  url: _vm.shareUrl,
                  title: _vm.shareTitle,
                  description: _vm.t("common.share_anrcade_text")
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("menu-panel", { ref: "panelLegals" }, [
            _c("h2", { staticClass: "c-MenuPanel-heading t-h5" }, [
              _vm._v(_vm._s(_vm.t("common.cta_legals")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "t-wysiwyg" }, [
              _c("h1", [_vm._v("HTML Ipsum Presents")]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Pellentesque habitant morbi tristique")]),
                _vm._v(
                  " senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. "
                ),
                _c("em", [_vm._v("Aenean ultricies mi vitae est.")]),
                _vm._v(
                  " Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, "
                ),
                _c("code", [_vm._v("commodo vitae")]),
                _vm._v(
                  ", ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. "
                ),
                _c("a", { attrs: { href: "#" } }, [_vm._v("Donec non enim")]),
                _vm._v(" in turpis pulvinar facilisis. Ut felis.")
              ]),
              _vm._v(" "),
              _c("h2", [_vm._v("Header Level 2")]),
              _vm._v(" "),
              _c("ol", [
                _c("li", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit."
                  )
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Aliquam tincidunt mauris eu risus.")])
              ]),
              _vm._v(" "),
              _c("blockquote", [
                _c("p", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa. Donec eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Header Level 3")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit."
                  )
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Aliquam tincidunt mauris eu risus.")])
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("Header Level 4")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent auctor ipsum elit, at consequat dolor blandit nec. Etiam posuere risus quis tellus blandit ullamcorper. Sed porttitor gravida dui, ac tempus lectus tempor quis. Quisque augue nisl, viverra nec convallis eu, viverra tincidunt dui. Morbi bibendum, sapien eget porta sollicitudin, ex urna molestie magna, id dapibus ex eros in dui. Nulla eget nisl dui. Donec eu euismod enim. In mi ipsum, dictum sit amet velit vitae, tincidunt tincidunt dolor. In aliquet augue vitae felis consequat, vel dapibus orci tincidunt. Nulla gravida libero sit amet justo gravida mollis quis eget dui. Etiam erat nulla, pulvinar eget sollicitudin et, commodo elementum massa. Maecenas ultricies, dolor vel pellentesque laoreet, mauris metus egestas nibh, in hendrerit ligula orci in nisl. Nam felis dui, aliquet non sodales quis, interdum id diam."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Etiam eget scelerisque lorem. Pellentesque ipsum odio, ullamcorper at viverra sed, vestibulum eget lorem. Donec pulvinar efficitur quam in posuere. Praesent a dictum dolor. Maecenas et commodo lorem. Sed id ultricies nunc. Nullam ullamcorper laoreet nisi, ut rhoncus turpis tincidunt eget. Proin vel leo sit amet felis faucibus aliquet. Cras tempus a leo at malesuada. Aliquam iaculis hendrerit hendrerit. Nulla imperdiet magna libero, sed vulputate ante sagittis vel."
                )
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("Header Level 5")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent auctor ipsum elit, at consequat dolor blandit nec. Etiam posuere risus quis tellus blandit ullamcorper. Sed porttitor gravida dui, ac tempus lectus tempor quis. Quisque augue nisl, viverra nec convallis eu, viverra tincidunt dui. Morbi bibendum, sapien eget porta sollicitudin, ex urna molestie magna, id dapibus ex eros in dui. Nulla eget nisl dui. Donec eu euismod enim. In mi ipsum, dictum sit amet velit vitae, tincidunt tincidunt dolor. In aliquet augue vitae felis consequat, vel dapibus orci tincidunt. Nulla gravida libero sit amet justo gravida mollis quis eget dui. Etiam erat nulla, pulvinar eget sollicitudin et, commodo elementum massa. Maecenas ultricies, dolor vel pellentesque laoreet, mauris metus egestas nibh, in hendrerit ligula orci in nisl. Nam felis dui, aliquet non sodales quis, interdum id diam."
                )
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit."
                  )
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Aliquam tincidunt mauris eu risus.")])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Etiam eget scelerisque lorem. Pellentesque ipsum odio, ullamcorper at viverra sed, vestibulum eget lorem. Donec pulvinar efficitur quam in posuere. Praesent a dictum dolor. Maecenas et commodo lorem. Sed id ultricies nunc. Nullam ullamcorper laoreet nisi, ut rhoncus turpis tincidunt eget. Proin vel leo sit amet felis faucibus aliquet. Cras tempus a leo at malesuada. Aliquam iaculis hendrerit hendrerit. Nulla imperdiet magna libero, sed vulputate ante sagittis vel."
                )
              ]),
              _vm._v(" "),
              _c("h6", [_vm._v("Header Level 6")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent auctor ipsum elit, at consequat dolor blandit nec. Etiam posuere risus quis tellus blandit ullamcorper. Sed porttitor gravida dui, ac tempus lectus tempor quis. Quisque augue nisl, viverra nec convallis eu, viverra tincidunt dui. Morbi bibendum, sapien eget porta sollicitudin, ex urna molestie magna, id dapibus ex eros in dui. Nulla eget nisl dui. Donec eu euismod enim. In mi ipsum, dictum sit amet velit vitae, tincidunt tincidunt dolor. In aliquet augue vitae felis consequat, vel dapibus orci tincidunt. Nulla gravida libero sit amet justo gravida mollis quis eget dui. Etiam erat nulla, pulvinar eget sollicitudin et, commodo elementum massa. Maecenas ultricies, dolor vel pellentesque laoreet, mauris metus egestas nibh, in hendrerit ligula orci in nisl. Nam felis dui, aliquet non sodales quis, interdum id diam."
                )
              ]),
              _vm._v(" "),
              _c("ol", [
                _c("li", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit."
                  )
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Aliquam tincidunt mauris eu risus.")])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Etiam eget scelerisque lorem. Pellentesque ipsum odio, ullamcorper at viverra sed, vestibulum eget lorem. Donec pulvinar efficitur quam in posuere. Praesent a dictum dolor. Maecenas et commodo lorem. Sed id ultricies nunc. Nullam ullamcorper laoreet nisi, ut rhoncus turpis tincidunt eget. Proin vel leo sit amet felis faucibus aliquet. Cras tempus a leo at malesuada. Aliquam iaculis hendrerit hendrerit. Nulla imperdiet magna libero, sed vulputate ante sagittis vel."
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("menu-panel", { ref: "panelTerms" }, [
            _c("h2", { staticClass: "c-MenuPanel-heading t-h5" }, [
              _vm._v(_vm._s(_vm.t("common.cta_terms")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "t-wysiwyg" }, [
              _c("h1", [_vm._v("Header Level 1")]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Pellentesque habitant morbi tristique")]),
                _vm._v(
                  " senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. "
                ),
                _c("em", [_vm._v("Aenean ultricies mi vitae est.")]),
                _vm._v(
                  " Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, "
                ),
                _c("code", [_vm._v("commodo vitae")]),
                _vm._v(
                  ", ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. "
                ),
                _c("a", { attrs: { href: "#" } }, [_vm._v("Donec non enim")]),
                _vm._v(" in turpis pulvinar facilisis. Ut felis.")
              ]),
              _vm._v(" "),
              _c("h2", [_vm._v("Header Level 2")]),
              _vm._v(" "),
              _c("ol", [
                _c("li", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit."
                  )
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Aliquam tincidunt mauris eu risus.")])
              ]),
              _vm._v(" "),
              _c("blockquote", [
                _c("p", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa. Donec eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Header Level 3")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit."
                  )
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Aliquam tincidunt mauris eu risus.")])
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("Header Level 4")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent auctor ipsum elit, at consequat dolor blandit nec. Etiam posuere risus quis tellus blandit ullamcorper. Sed porttitor gravida dui, ac tempus lectus tempor quis. Quisque augue nisl, viverra nec convallis eu, viverra tincidunt dui. Morbi bibendum, sapien eget porta sollicitudin, ex urna molestie magna, id dapibus ex eros in dui. Nulla eget nisl dui. Donec eu euismod enim. In mi ipsum, dictum sit amet velit vitae, tincidunt tincidunt dolor. In aliquet augue vitae felis consequat, vel dapibus orci tincidunt. Nulla gravida libero sit amet justo gravida mollis quis eget dui. Etiam erat nulla, pulvinar eget sollicitudin et, commodo elementum massa. Maecenas ultricies, dolor vel pellentesque laoreet, mauris metus egestas nibh, in hendrerit ligula orci in nisl. Nam felis dui, aliquet non sodales quis, interdum id diam."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Etiam eget scelerisque lorem. Pellentesque ipsum odio, ullamcorper at viverra sed, vestibulum eget lorem. Donec pulvinar efficitur quam in posuere. Praesent a dictum dolor. Maecenas et commodo lorem. Sed id ultricies nunc. Nullam ullamcorper laoreet nisi, ut rhoncus turpis tincidunt eget. Proin vel leo sit amet felis faucibus aliquet. Cras tempus a leo at malesuada. Aliquam iaculis hendrerit hendrerit. Nulla imperdiet magna libero, sed vulputate ante sagittis vel."
                )
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("Header Level 5")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent auctor ipsum elit, at consequat dolor blandit nec. Etiam posuere risus quis tellus blandit ullamcorper. Sed porttitor gravida dui, ac tempus lectus tempor quis. Quisque augue nisl, viverra nec convallis eu, viverra tincidunt dui. Morbi bibendum, sapien eget porta sollicitudin, ex urna molestie magna, id dapibus ex eros in dui. Nulla eget nisl dui. Donec eu euismod enim. In mi ipsum, dictum sit amet velit vitae, tincidunt tincidunt dolor. In aliquet augue vitae felis consequat, vel dapibus orci tincidunt. Nulla gravida libero sit amet justo gravida mollis quis eget dui. Etiam erat nulla, pulvinar eget sollicitudin et, commodo elementum massa. Maecenas ultricies, dolor vel pellentesque laoreet, mauris metus egestas nibh, in hendrerit ligula orci in nisl. Nam felis dui, aliquet non sodales quis, interdum id diam."
                )
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit."
                  )
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Aliquam tincidunt mauris eu risus.")])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Etiam eget scelerisque lorem. Pellentesque ipsum odio, ullamcorper at viverra sed, vestibulum eget lorem. Donec pulvinar efficitur quam in posuere. Praesent a dictum dolor. Maecenas et commodo lorem. Sed id ultricies nunc. Nullam ullamcorper laoreet nisi, ut rhoncus turpis tincidunt eget. Proin vel leo sit amet felis faucibus aliquet. Cras tempus a leo at malesuada. Aliquam iaculis hendrerit hendrerit. Nulla imperdiet magna libero, sed vulputate ante sagittis vel."
                )
              ]),
              _vm._v(" "),
              _c("h6", [_vm._v("Header Level 6")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent auctor ipsum elit, at consequat dolor blandit nec. Etiam posuere risus quis tellus blandit ullamcorper. Sed porttitor gravida dui, ac tempus lectus tempor quis. Quisque augue nisl, viverra nec convallis eu, viverra tincidunt dui. Morbi bibendum, sapien eget porta sollicitudin, ex urna molestie magna, id dapibus ex eros in dui. Nulla eget nisl dui. Donec eu euismod enim. In mi ipsum, dictum sit amet velit vitae, tincidunt tincidunt dolor. In aliquet augue vitae felis consequat, vel dapibus orci tincidunt. Nulla gravida libero sit amet justo gravida mollis quis eget dui. Etiam erat nulla, pulvinar eget sollicitudin et, commodo elementum massa. Maecenas ultricies, dolor vel pellentesque laoreet, mauris metus egestas nibh, in hendrerit ligula orci in nisl. Nam felis dui, aliquet non sodales quis, interdum id diam."
                )
              ]),
              _vm._v(" "),
              _c("ol", [
                _c("li", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit."
                  )
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Aliquam tincidunt mauris eu risus.")])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Etiam eget scelerisque lorem. Pellentesque ipsum odio, ullamcorper at viverra sed, vestibulum eget lorem. Donec pulvinar efficitur quam in posuere. Praesent a dictum dolor. Maecenas et commodo lorem. Sed id ultricies nunc. Nullam ullamcorper laoreet nisi, ut rhoncus turpis tincidunt eget. Proin vel leo sit amet felis faucibus aliquet. Cras tempus a leo at malesuada. Aliquam iaculis hendrerit hendrerit. Nulla imperdiet magna libero, sed vulputate ante sagittis vel."
                )
              ])
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }