export var CubeFace;
(function (CubeFace) {
    CubeFace[CubeFace["TEXTURE_CUBE_MAP_POSITIVE_X"] = 34069] = "TEXTURE_CUBE_MAP_POSITIVE_X";
    CubeFace[CubeFace["TEXTURE_CUBE_MAP_NEGATIVE_X"] = 34070] = "TEXTURE_CUBE_MAP_NEGATIVE_X";
    CubeFace[CubeFace["TEXTURE_CUBE_MAP_POSITIVE_Y"] = 34071] = "TEXTURE_CUBE_MAP_POSITIVE_Y";
    CubeFace[CubeFace["TEXTURE_CUBE_MAP_NEGATIVE_Y"] = 34072] = "TEXTURE_CUBE_MAP_NEGATIVE_Y";
    CubeFace[CubeFace["TEXTURE_CUBE_MAP_POSITIVE_Z"] = 34073] = "TEXTURE_CUBE_MAP_POSITIVE_Z";
    CubeFace[CubeFace["TEXTURE_CUBE_MAP_NEGATIVE_Z"] = 34074] = "TEXTURE_CUBE_MAP_NEGATIVE_Z";
})(CubeFace || (CubeFace = {}));
export function cubeFaceForSurface(i) {
    switch (i) {
        case 0: return CubeFace.TEXTURE_CUBE_MAP_POSITIVE_X;
        case 1: return CubeFace.TEXTURE_CUBE_MAP_POSITIVE_Y;
        case 2: return CubeFace.TEXTURE_CUBE_MAP_POSITIVE_Z;
        case 3: return CubeFace.TEXTURE_CUBE_MAP_NEGATIVE_X;
        case 4: return CubeFace.TEXTURE_CUBE_MAP_NEGATIVE_Y;
        case 5: return CubeFace.TEXTURE_CUBE_MAP_NEGATIVE_Z;
    }
}
export function surfaceForCubeFace(face) {
    switch (face) {
        case CubeFace.TEXTURE_CUBE_MAP_POSITIVE_X: return 0;
        case CubeFace.TEXTURE_CUBE_MAP_POSITIVE_Y: return 1;
        case CubeFace.TEXTURE_CUBE_MAP_POSITIVE_Z: return 2;
        case CubeFace.TEXTURE_CUBE_MAP_NEGATIVE_X: return 3;
        case CubeFace.TEXTURE_CUBE_MAP_NEGATIVE_Y: return 4;
        case CubeFace.TEXTURE_CUBE_MAP_NEGATIVE_Z: return 5;
    }
}
export var TextureDataType;
(function (TextureDataType) {
    TextureDataType[TextureDataType["RAW"] = 0] = "RAW";
    TextureDataType[TextureDataType["RAW_COMPRESSED"] = 1] = "RAW_COMPRESSED";
    TextureDataType[TextureDataType["IMAGE"] = 2] = "IMAGE";
})(TextureDataType || (TextureDataType = {}));
