/// <
import ExtensionsRegistry from './extensions/Registry';
import NanoCamera from 'nanogl-camera';
import NanoglNode from 'nanogl-node';
import { DefaultSemantics } from './Semantics';
import GltfTypes from './types/GltfTypes';
import { DefaultRenderConfig } from './IRenderConfig';
class ElementCollection {
    constructor() {
        // private _byNames : Map<string, T> = new Map()
        this.indexed = [];
        this.list = [];
    }
    addElement(element, index = -1) {
        if (index > -1)
            this.indexed[index] = element;
        this.list.push(element);
    }
}
/** Gltf file representation */
export default class Gltf {
    constructor() {
        this.root = new NanoglNode();
        this.extras = {};
        this._collections = new Map([
            [GltfTypes.ACCESSOR, new ElementCollection()],
            [GltfTypes.ACCESSOR_SPARSE, new ElementCollection()],
            [GltfTypes.ACCESSOR_SPARSE_INDICES, new ElementCollection()],
            [GltfTypes.ACCESSOR_SPARSE_VALUES, new ElementCollection()],
            [GltfTypes.ANIMATION, new ElementCollection()],
            [GltfTypes.ANIMATION_SAMPLER, new ElementCollection()],
            [GltfTypes.ANIMATION_CHANNEL, new ElementCollection()],
            [GltfTypes.ASSET, new ElementCollection()],
            [GltfTypes.BUFFER, new ElementCollection()],
            [GltfTypes.BUFFERVIEW, new ElementCollection()],
            [GltfTypes.CAMERA, new ElementCollection()],
            [GltfTypes.IMAGE, new ElementCollection()],
            [GltfTypes.MATERIAL, new ElementCollection()],
            [GltfTypes.MESH, new ElementCollection()],
            [GltfTypes.NODE, new ElementCollection()],
            [GltfTypes.NORMAL_TEXTURE_INFO, new ElementCollection()],
            [GltfTypes.OCCLUSION_TEXTURE_INFO, new ElementCollection()],
            [GltfTypes.PRIMITIVE, new ElementCollection()],
            [GltfTypes.SAMPLER, new ElementCollection()],
            [GltfTypes.SCENE, new ElementCollection()],
            [GltfTypes.SKIN, new ElementCollection()],
            [GltfTypes.TEXTURE, new ElementCollection()],
            [GltfTypes.TEXTURE_INFO, new ElementCollection()],
        ]);
        this._elements = [];
    }
    static addExtension(ext) {
        Gltf._extensionsRegistry.addExtension(ext);
    }
    static getSemantics() {
        return this._semantics;
    }
    static setSemantics(semantics) {
        semantics !== null && semantics !== void 0 ? semantics : (this._semantics = semantics);
    }
    static getRenderConfig() {
        return this._renderConfig;
    }
    static getExtensionsRegistry() {
        return this._extensionsRegistry;
    }
    async allocateGl(gl) {
        var _a;
        const allocPromises = [];
        for (const element of this._elements) {
            const p = (_a = element.allocateGl) === null || _a === void 0 ? void 0 : _a.call(element, gl);
            p !== null && p !== void 0 ? p : allocPromises.push(p);
        }
        await Promise.all(allocPromises);
        this.renderables = this.nodes
            .map(n => n.renderable)
            .filter(n => n !== undefined);
        for (const node of this.nodes) {
            if (!node._parent) {
                this.root.add(node);
            }
        }
        this.createCameras();
    }
    createCameras() {
        this.cameraInstances = this.nodes
            .filter(n => n.camera !== undefined)
            .map(n => {
            const cam = new NanoCamera(n.camera.lens);
            n.add(cam);
            return cam;
        });
    }
    get buffers() {
        return this._getCollection(GltfTypes.BUFFER).list;
    }
    get bufferViews() {
        return this._getCollection(GltfTypes.BUFFERVIEW).list;
    }
    get accessors() {
        return this._getCollection(GltfTypes.ACCESSOR).list;
    }
    get animations() {
        return this._getCollection(GltfTypes.ANIMATION).list;
    }
    get meshes() {
        return this._getCollection(GltfTypes.MESH).list;
    }
    get nodes() {
        return this._getCollection(GltfTypes.NODE).list;
    }
    get materials() {
        return this._getCollection(GltfTypes.MATERIAL).list;
    }
    get cameras() {
        return this._getCollection(GltfTypes.CAMERA).list;
    }
    get skins() {
        return this._getCollection(GltfTypes.SKIN).list;
    }
    getAllElements() {
        return this._elements;
    }
    getElement(type, index) {
        return this._getCollection(type).indexed[index];
    }
    getElementByName(type, name) {
        const list = this._getCollection(type).list;
        for (var el of list) {
            if (el.name === name)
                return el;
        }
        return null;
    }
    getElementsByName(type, name) {
        const list = this._getCollection(type).list;
        const ouput = [];
        for (var el of list) {
            if (el.name === name)
                ouput.push(el);
        }
        return ouput;
    }
    _getCollection(type) {
        return this._collections.get(type);
    }
    addElement(element, index = -1) {
        const collection = this._getCollection(element.gltftype);
        collection.addElement(element, index);
        this._elements.push(element);
    }
}
Gltf._extensionsRegistry = new ExtensionsRegistry();
Gltf._semantics = new DefaultSemantics();
Gltf._renderConfig = DefaultRenderConfig();
