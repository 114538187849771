import { GAME_ID } from "datas";
import { gsap, Quart, Quad } from 'gsap';
import { RAF } from 'lol/js/dom/raf';
import BrowserDetect from 'vendors/BrowserDetect';
import PushProduct from "components/push-product/push-product.vue";
import MenuPanel from "components/menu-panel/menu-panel.vue";
import Share from "components/share/share.vue";
import ObjectFit from "components/object-fit/object-fit.vue";
import LangSelector from "components/lang-selector/lang-selector.vue";
import { I18n } from "core/I18n";
import locales from "datas/locales-list.json";
export default {
  name: 'Menu',
  components: {
    PushProduct,
    MenuPanel,
    Share,
    ObjectFit,
    LangSelector
  },
  props: {
    productImg: {
      type: HTMLImageElement | Object
    },
    pushImg: {
      type: HTMLImageElement | Object
    },
    introThumbnail: {
      type: HTMLImageElement | Object
    },
    hubThumbnail: {
      type: HTMLImageElement | Object
    },
    gameThumbnails: {
      type: Array
    }
  },

  data() {
    return {
      isHidden: true,
      navOpen: false,
      isPanelOpen: false,
      games: []
    };
  },

  computed: {
    shareUrl() {
      return `${location.protocol}//${location.host}${location.pathname}`;
    },

    shareTitle() {
      return document.title;
    },

    languages() {
      let languages = null;
      const currentCountry = this.currentLocale.slice(this.currentLocale.length - 2, this.currentLocale.length);
      locales.forEach(item => {
        if (item.locale === currentCountry) {
          languages = item.languages;
        }
      });
      return languages;
    }

  },
  watch: {
    '$appdatas.store.menuOpen': function (isOpen) {
      if (!isOpen) {
        this.onNavClose();
        this.$appdatas.store.pageTracking = this.oldTrackingPage;
        this.isPanelOpen = false;
        this.$refs.panelShare && this.$refs.panelShare.isOpen && this.$refs.panelShare.forceClose();
        this.$refs.panelTerms && this.$refs.panelLegals.isOpen && this.$refs.panelLegals.forceClose();
        this.$refs.panelTerms && this.$refs.panelTerms.isOpen && this.$refs.panelTerms.forceClose();
      } else {
        this.onNavOpen();
        this.oldTrackingPage = this.$appdatas.store.pageTracking;
        this.$appdatas.store.pageTracking = '/menu';
      }
    },
    '$appdatas.store.screen.width': function (value) {
      this.resize();
    },
    '$appdatas.store.screen.height': function (value) {
      this.resize();
    }
  },

  created() {
    this.games = Object.values(GAME_ID);
    RAF.subscribe("menuCanvas", this.update.bind(this));
  },

  mounted() {
    this.ctx = this.$refs.canvas.getContext('2d');
    this.resize();
  },

  beforeDestroy() {
    RAF.unsubscribe("menuCanvas", this.update.bind(this));
  },

  methods: {
    resize() {
      const width = this.$appdatas.store.screen.width >= 1024 ? Math.max(this.$appdatas.store.screen.width / 12 * 4, 375) : this.$appdatas.store.screen.width;
      this.$refs.canvas.width = width;
      this.$refs.canvas.height = this.$appdatas.store.screen.height;
      this.origin = {
        // position of burger
        x: 50,
        y: 45
      }; // calcul hypothenuse

      this.targetRadius = Math.sqrt(Math.pow(width - this.origin.x, 2) + Math.pow(this.$appdatas.store.screen.height - this.origin.y, 2));

      if (this.navOpen && !this.isAnim) {
        this.radius = this.targetRadius;
      } else {
        this.radius = 15;
      }

      this.ctx.beginPath();
      this.ctx.arc(this.origin.x, this.origin.y, this.radius, 0, 2 * Math.PI, false);
      this.ctx.fillStyle = '#ffffff';
      this.ctx.fill();
    },

    update() {
      if (!this.isAnim) return;
      this.ctx.clearRect(0, 0, this.$appdatas.store.screen.width, this.$appdatas.store.screen.height);
      this.ctx.beginPath();
      this.ctx.arc(this.origin.x, this.origin.y, this.radius, 0, 2 * Math.PI, false);
      this.ctx.fillStyle = '#ffffff';
      this.ctx.fill();
    },

    onNavOpen() {
      this.isHidden = false;

      if (this.tween) {
        this.tween.pause();
      } // wait "display block" before run appear animation


      clearTimeout(this.openTimeout);
      this.openTimeout = setTimeout(() => {
        this.navOpen = true;
        this.tween = gsap.to(this, {
          duration: .8,
          radius: this.targetRadius,
          ease: Quart.easeInOut,
          onStart: () => {
            this.isAnim = true;
          },
          onComplete: () => {
            this.isAnim = false;
          }
        });
      }, 40);
    },

    onNavClose() {
      this.navOpen = false;
      this.tween = gsap.to(this, {
        duration: 0.6,
        radius: 15,
        ease: Quart.easeInOut,
        onStart: () => {
          this.isAnim = true;
        },
        onComplete: () => {
          this.isAnim = false;
          this.isHidden = true;
        }
      });
    },

    instantClose() {
      this.navOpen = false;
      this.radius = 15;
      this.ctx.clearRect(0, 0, this.$appdatas.store.screen.width, this.$appdatas.store.screen.height);
    },

    // Panels
    openSharePanel() {
      this.isPanelOpen = true;
      this.$refs.panelShare.open();
      this.$appdatas.sendEvent('navigation', 'sharing', 'menu');
    },

    openLegalsPanel() {
      this.isPanelOpen = true;
      this.$refs.panelLegals.open();
      this.$appdatas.store.pageTracking = '/legalmentions';
    },

    openTermsPanel() {
      this.isPanelOpen = true;
      this.$refs.panelTerms.open();
      this.$appdatas.store.pageTracking = '/termsandconditions';
    },

    closeActivePanel() {
      this.isPanelOpen = false;
      this.$refs.panelShare && this.$refs.panelShare.isOpen && this.$refs.panelShare.close();
      this.$refs.panelTerms && this.$refs.panelLegals.isOpen && this.$refs.panelLegals.close();
      this.$refs.panelTerms && this.$refs.panelTerms.isOpen && this.$refs.panelTerms.close();
      this.$appdatas.store.pageTracking = '/menu';
    },

    formatGameString(gameStr) {
      return gameStr.toLowerCase().replace(/_/g, "");
    },

    onLocaleChange(lang) {
      this.$router.push({
        name: this.$router.currentRoute.name,
        params: {
          lang
        }
      });
    }

  }
};