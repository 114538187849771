import Vue from "vue";
import { registerGLApp } from "mixins/GLAppProvider";
import GLViewer from 'components/misc/GLViewer.vue';
import VMenu from "components/v-menu/v-menu.vue";
import Layout from "modules/layout/Layout.vue";
import SamplesMenu from "components/samples-menu/samples-menu.vue";
export default {
  name: 'app',

  data() {
    return {
      devMenu: false
    };
  },

  components: {
    GLViewer,
    VMenu,
    Layout,
    SamplesMenu
  },

  beforeCreate() {
    Layout._module.instantiateModule();
  },

  async created() {
    var params = new URLSearchParams(window.location.search);
    this.devMenu = params.get("dev-menu") != null;
    this.$appdatas.store.skipInstruction = params.get("skip-inst") != null;
    this.$appdatas.store.utm_source = params.get("utm_source");
    this.$appdatas.store.utm_campaign = params.get("utm_campaign");
    this.$appdatas.store.isRetail = params.get("retail") != null;

    Layout._module.methods.getModule().init(null);

    Layout._module.methods.getModule().preload();

    Layout._module.methods.getModule().load();
  },

  mounted() {
    registerGLApp(this.$refs.viewer.glapp);
  }

};